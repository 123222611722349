import useActuatorValueStore from 'store/useActuatorValueStore';
import SpeedTableItem from './SpeedTableItem';

export default function SpeedTableBody() {
	const { strokeIntervalsCount } = useActuatorValueStore();
	return (
		<div
			className="grid flex-1 divide-x divide-white"
			style={{
				gridTemplateColumns: `repeat(${strokeIntervalsCount}, 1fr)`,
			}}
		>
			{Array(strokeIntervalsCount)
				.fill(0)
				.map((_, index) => (
					<SpeedTableItem key={index} rangeNumber={index + 1} />
				))}
		</div>
	);
}
