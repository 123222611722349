import LoginBox from 'components/login/LoginBox';
import WelcomeBox from 'components/login/welcome/WelcomeBox';
import useAuthStore from 'store/useAuthStore';

export default function LoginPage() {
	const { accessToken } = useAuthStore();

	return (
		<main className="flex flex-col items-center justify-center h-screen">
			{accessToken ? <WelcomeBox /> : <LoginBox />}
		</main>
	);
}
