import { useTranslation } from 'react-i18next';

type Props = {
	onClose: () => void;
};

export default function ModalConfirmButton({ onClose }: Props) {
	const { t } = useTranslation();
	const handleClick = () => {
		onClose();
	};
	return (
		<button
			className="rounded px-2.5 py-[14px] flex items-center justify-center bg-primary-blue flex-1 h-[47px]"
			onClick={handleClick}
		>
			<span className="font-bold text-white">{t('communication.confirm')}</span>
		</button>
	);
}
