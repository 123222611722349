import customAxios from 'api';
import axios from 'axios';

// 회원탈퇴
export const userDelete = async () => {
	try {
		const response = await customAxios.delete('/user/userDelete.php');
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unexpected error:', error);
		}
		throw error;
	}
};
