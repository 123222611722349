import SpeedControlContents from './SpeedControlContents';
import SpeedControlHeader from './SpeedControlHeader';
import SpeedSaveButton from './SpeedSaveButton';
import StrokeIntervalForm from './stroke_intervals/StrokeIntervalForm';
import clsx from 'clsx';

export default function SpeedControlArea() {
	return (
		<section className={clsx('px-5 py-3 flex flex-col gap-y-[15px]')}>
			<SpeedControlHeader />
			<SpeedControlContents />
			<div className="flex gap-x-5">
				<StrokeIntervalForm /> <SpeedSaveButton />
			</div>
		</section>
	);
}
