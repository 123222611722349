import { useTranslation } from 'react-i18next';
import StrokeLegendButton from './legend_buttons/StrokeLegendButton';
import SpeedLegendButton from './legend_buttons/SpeedLegendButton';
import DetectionLegendButton from './legend_buttons/DetectionLegentButton';

export default function ActuatorGraphHeader() {
	const { t } = useTranslation();
	return (
		<header className="flex items-center justify-between w-full">
			<h3 className="font-bold w-[140px] whitespace-nowrap">
				{t('actuator.operation_graph.title')}
			</h3>
			<div className="flex items-center gap-x-1">
				<StrokeLegendButton />
				<SpeedLegendButton />
				<DetectionLegendButton />
			</div>
		</header>
	);
}
