import customAxios from 'api';

export type MotorInfo = {
	id: number;
	partner: string;
	model_name: string;
	model_image: string;
	push: number;
	pull: number;
	self_locking: number;
	extended_length: number;
	stroke: number;
	hall_counter: number;
	voltage: number;
};
type Response = MotorInfo[];

export const getMotorInfos = async () => {
	try {
		const response = await customAxios.get<Response>('/hardware/motorInfo.php');
		return response.data;
	} catch (error) {
		throw error;
	}
};
