export default function MsecLineInput() {
	const unit = 'msec/line';

	return (
		<div className="flex-1 flex relative">
			<input
				type="text"
				pattern="[0-9]*"
				className="flex-1 px-[14px] py-3 border border-sub-color "
			/>
			<span className="absolute text-sm text-sub-text right-0 top-1/2 transform -translate-y-1/2 pr-2">
				{unit}
			</span>
		</div>
	);
}
