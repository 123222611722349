import clsx from 'clsx';
import { useMotorInfoQuery } from 'query/useMotorInfoQuery';
import useActuatorSelectionStore from 'store/useSelectedMotorModel';

export default function PartnersSidebar() {
	const { selectedPartner, setSelectedPartner } = useActuatorSelectionStore();
	const { data } = useMotorInfoQuery();

	if (!data) return null;
	const { partners } = data;
	return (
		<div id="actuator-modal-company-list" className="w-[170px] flex flex-col">
			{partners.map((company, index) => (
				<button
					key={index}
					className={clsx(
						'hover:bg-slate-100 p-2.5 gap-x-2.5 flex items-center',
						{ 'bg-selected-gray': company === selectedPartner }
					)}
					onClick={() => setSelectedPartner(company)}
				>
					{/* <img
						width={32}
						height={32}
						className="object-cover rounded-full h-[32px]"
						src={`${process.env.PUBLIC_URL}/image/sample-company.png`}
						alt={company}
					/> */}
					<span>{company}</span>
				</button>
			))}
		</div>
	);
}
