import { useTranslation } from 'react-i18next';
import { getButtonStyle } from '.';
import CheckBox from 'components/common/CheckBox';
import useActuatorValueStore from 'store/useActuatorValueStore';

export default function CloseLimitOpenHallButton() {
	const { t } = useTranslation();
	const { sensorSettings, setSensorSettings } = useActuatorValueStore();

	const checked = sensorSettings === 'close-limit-open-hall';
	const onClick = () => {
		if (checked) setSensorSettings('none');
		else setSensorSettings('close-limit-open-hall');
	};
	return (
		<button className={getButtonStyle(checked)} onClick={onClick}>
			<CheckBox checked={checked} />
			<span className="text-sm line-clamp-2">
				{t('actuator.check_type.close_limit_open_hall')}
			</span>
		</button>
	);
}
