import { isEmailDuplicate } from 'api/auth/isEmailDuplicate';
import { userSignUp } from 'api/auth/userSignUp';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'store/useAuthStore';
import useSignupStore from 'store/useSignupStore';
import { checkPasswordFormat, isValidateEmail } from 'utils/validation';

export function useSignup() {
	const { setAccessToken } = useAuthStore();
	const {
		email,
		setEmailCheckedStatus,
		password,
		confirmPassword,
		name,
		companyName,
		agreeToPrivacyPolicy,
		contactInfo,
	} = useSignupStore();

	const navigate = useNavigate();
	//이메일 중복 확인 submit
	const onSubmitEmail = async () => {
		// 서버에 요청 보내기
		setEmailCheckedStatus('loading');
		try {
			const res = await isEmailDuplicate({ email: email });
			if (res.data.isDuplicate === true) {
				setEmailCheckedStatus('duplicate');
				// 중복된 이메일
				return;
			}
			setEmailCheckedStatus('success');
		} catch (error) {
			console.error('이메일 중복 확인 에러:', error);

			setEmailCheckedStatus('fail');
			//에러 발생 시 3초 후 초기화
			setTimeout(() => {
				setEmailCheckedStatus('none');
			}, 3000);
		}
	};

	//회원가입 submit
	const onSubmitSignup = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			// 서버에 회원가입 요청 보내기: userSignUp
			const signupResult = await userSignUp({
				email: email,
				password: password,
				name: name,
				company: companyName,
				contactInformation: contactInfo,
			});

			// 회원가입 성공 시 accessToken 저장
			const accessToken = signupResult.accessToken;
			setAccessToken(accessToken);
			navigate('/signup-success');
		} catch (error) {
			console.error('회원가입 에러:', error);
		}
	};

	const isEmailValid = isValidateEmail(email);
	const isPasswordValid =
		checkPasswordFormat(password) && password === confirmPassword;
	const isNameValid = name.length > 0;
	const isCompanyNameValid = companyName.length > 0;
	const isContactInfoValid = contactInfo.length > 0;
	const isAgreeToPrivacyPolicy = agreeToPrivacyPolicy;

	const submitDisabled = !(
		isEmailValid &&
		isPasswordValid &&
		isNameValid &&
		isCompanyNameValid &&
		isContactInfoValid &&
		isAgreeToPrivacyPolicy
	);

	return { onSubmitEmail, onSubmitSignup, checkPasswordFormat, submitDisabled };
}
