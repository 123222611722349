import {
	useDeleteMotorMutation,
	useMotorSettingsQuery,
} from 'query/useMotorSettingsQuery';
import { useState } from 'react';
import useSelectedActuatorStore from 'store/useSelectedActuatorStore';

export const useActuatorSelect = (actuatorNumber: number) => {
	const { mutateAsync: deleteMotor } = useDeleteMotorMutation();
	const { data: motorSettings } = useMotorSettingsQuery();
	const { selectedActuatorNumber, setSelectedActuatorNumber } =
		useSelectedActuatorStore();
	const [isDelectModalOpen, setDeleteModalOpen] = useState<boolean>(false);
	const isSelected = selectedActuatorNumber === actuatorNumber;

	const onDelete = async () => {
		const id = motorSettings?.find(
			(motorSetting) => motorSetting.actuator_number === actuatorNumber
		)?.id;
		if (!id) return;
		await deleteMotor(id);

		//현재 선택된 actuatorNumber가 삭제된 actuatorNumber와 같다면 선택된 actuatorNumber를 초기화한다.
		if (selectedActuatorNumber === actuatorNumber) {
			const remainActuatorNumber = motorSettings?.find(
				(motorSetting) => motorSetting.actuator_number !== actuatorNumber
			)?.actuator_number;
			setSelectedActuatorNumber(remainActuatorNumber || 0);
		}
	};

	return {
		isSelected,
		onDelete,
		setSelectedActuatorNumber,
		actuatorNumber,
		isDelectModalOpen,
		setDeleteModalOpen,
	};
};
