import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useResetStore from 'store/useResetStore';

export default function MatchedState() {
	const { t } = useTranslation();
	const { password, passwordConfirmation } = useResetStore();
	const matchedState =
		passwordConfirmation === ''
			? 'none'
			: password === passwordConfirmation
			? 'match'
			: 'not-match';
	return (
		<p className={getMatchedStyle(matchedState)}>
			{matchedState === 'none'
				? ''
				: matchedState === 'match'
				? t('reset.password_match')
				: t('reset.password_not_match')}
		</p>
	);
}

// style
const getMatchedStyle = (matchedState: 'match' | 'not-match' | 'none') =>
	clsx('text-sm', {
		'text-green-500': matchedState === 'match',
		'text-red-500': matchedState === 'not-match',
	});
