import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function SimpleLanguageSelector() {
	const { i18n, t } = useTranslation();
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng); // 언어 전환
	};
	return (
		<div className="flex gap-1">
			<button
				type="button"
				className={languageButtonStyle}
				onClick={() => changeLanguage('ko')}
			>
				{t('language.korean')}
			</button>
			<button
				type="button"
				className={languageButtonStyle}
				onClick={() => changeLanguage('en')}
			>
				{t('language.english')}
			</button>
		</div>
	);
}
const languageButtonStyle = clsx(
	'text-sm text-sub-text px-1 py-1 hover:bg-slate-100 transition',
	'flex items-center gap-x-1'
);
