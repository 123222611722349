import { useNavigate } from 'react-router-dom';
import { ReactComponent as LeftNavigation } from 'asset/icon/left-navigation.svg';
type Props = {
	className?: string;
};
export default function NavigationPreviousButton({ className }: Props) {
	const navigate = useNavigate();

	return (
		<button
			type="button"
			id="profile-back-button"
			onClick={() => navigate(-1)}
			className={className}
		>
			<LeftNavigation />
		</button>
	);
}
