import { create } from 'zustand';

interface SelectedActuatorStore {
	selectedActuatorNumber: number | null;
	setSelectedActuatorNumber: (actuatorNumber: number | null) => void;
}

const useSelectedActuatorStore = create<SelectedActuatorStore>((set) => ({
	selectedActuatorNumber: null,
	setSelectedActuatorNumber: (actuatorNumber) =>
		set({ selectedActuatorNumber: actuatorNumber }),
}));

export default useSelectedActuatorStore;
