import { contentsStyle, labelStyle } from './style';

export default function LengthSpec() {
	const extendedLength = 700;
	const compressedLength = 500;
	const stroke = 200;

	return (
		<div className="flex flex-col flex-1 gap-y-2">
			<span className={labelStyle}>Length</span>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Extended Length :</span>
				<span className={contentsStyle}>{extendedLength}</span>
			</div>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Compressed Length :</span>
				<span className={contentsStyle}>{compressedLength}</span>
			</div>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Stroke:</span>
				<span className={contentsStyle}>{stroke}</span>
			</div>
		</div>
	);
}
