import ReactModal from 'react-modal';
import { customModalStyles } from './style';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { parseNewlines } from 'utils/string';

type Props = {
	title?: string;
	description: string;
	isOpen: boolean;
	onClose: () => void;
	onConfirm?: () => void;
	onCancel?: () => void;
	cancelText?: string;
	confirmText?: string;
	cancel?: boolean;
};

export default function ConfrimModal(props: Props) {
	const { t } = useTranslation();
	const {
		isOpen,
		onClose,
		title,
		description,
		onConfirm,
		cancel = true,
		cancelText,
		confirmText,
		onCancel,
	} = props;
	const handleConfirm = () => {
		onConfirm && onConfirm();
		onClose();
	};
	const handleCancel = () => {
		onCancel && onCancel();
		onClose();
	};
	return (
		<ReactModal
			isOpen={isOpen}
			style={customModalStyles}
			onRequestClose={onClose}
			className={clsx('p-[25px] w-[350px] ')}
		>
			<div className="flex flex-col h-full gap-y-10">
				<div className="flex flex-col gap-y-[30px] items-center text-center pt-5">
					<p className="text-[22px] font-bold">
						{title || t('confirm.information')}
					</p>
					<p className="font-medium">{parseNewlines(description)}</p>
				</div>
				<div id="confirm-modal-buttons" className="h-[47px] flex gap-x-[15px]">
					{cancel && (
						<button
							id="cancel-button"
							onClick={handleCancel}
							className="flex items-center justify-center border rounded border-primary-blue px-2.5 w-[95px]"
						>
							<span className="font-extrabold text-primary-blue">
								{cancelText || t('confirm.cancel')}
							</span>
						</button>
					)}
					<button
						id="confirm-button"
						onClick={handleConfirm}
						className="flex items-center justify-center flex-1 rounded bg-primary-blue"
					>
						<span className="font-extrabold text-white">
							{confirmText || t('confirm.confirm')}
						</span>
					</button>
				</div>
			</div>
		</ReactModal>
	);
}
