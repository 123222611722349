import useActuatorValueStore from 'store/useActuatorValueStore';
import StrokePercent from './speed_graph/StrokePercent';
import VariableSpeedGraph from './speed_graph/VariableSpeedGraph';
import SpeedTable from './speed_table/SpeedTable';
import clsx from 'clsx';

export default function SpeedControlContents() {
	const { speedMode } = useActuatorValueStore();

	return (
		<div
			className={clsx('flex flex-col gap-y-[14px]', {
				'opacity-50 pointer-events-none ': speedMode === 'single',
			})}
		>
			<div>
				<div className="ml-[35px] mr-4 ">
					<StrokePercent />
				</div>

				<div className="w-[700px] h-[224px] ">
					<VariableSpeedGraph />
				</div>
				<div>
					<SpeedTable />
				</div>
			</div>
		</div>
	);
}
// width={700} height={224}
