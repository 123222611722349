import ActuatorGraph from './ActuatorGraph';
import ActuatorGraphHeader from './ActuatorGraphHeader';
import AsyncBoundary from 'components/common/AsyncBoundary';

export default function ActuatorGraphBox() {
	return (
		<section className="flex flex-col p-5 gap-y-3">
			<ActuatorGraphHeader />
			<AsyncBoundary SuspenseFallback={<div>Loading...</div>}>
				<div className="min-[1341px]:w-[500px] h-[224px]">
					<ActuatorGraph />
				</div>
			</AsyncBoundary>
		</section>
	);
}
