import { useTranslation } from 'react-i18next';
import useSignupStore from 'store/useSignupStore';
import { inputStyle, labelStyle } from '.';

export default function CompanyNameField() {
	const { t } = useTranslation();
	const { companyName, setCompanyName } = useSignupStore();
	return (
		<div id="signup-name-field" className="flex flex-col gap-y-2">
			<label htmlFor="name" className={labelStyle}>
				{t('signup.company_name_label')}
			</label>
			<input
				className={inputStyle}
				type="text"
				id="name"
				name="name"
				value={companyName}
				onChange={(e) => setCompanyName(e.target.value)}
				placeholder={t('signup.company_name_placeholder')}
				required
			/>
		</div>
	);
}
