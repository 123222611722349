import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function WithdrawCancelButton() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const onCancel = () => {
		navigate(-1);
	};

	return (
		<button
			id="cancel-cancel-account-button"
			className="bg-white px-2.5 py-[14px] font-bold text-primary-blue border border-primary-blue rounded w-[147px]"
			onClick={onCancel}
		>
			{t('account_cancel.cancel')}
		</button>
	);
}
