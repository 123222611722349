import { ReactComponent as Arrow } from 'asset/icon/arrow-down.svg';
import useCancelAgreementStore from 'store/useCancleAgreementStore';
import { subText } from 'utils/color';
import CancelAgreementModal from './agreement/CancelAgreementModal';

export default function ModalToReviewButton() {
	const { isModalOpen, setModalOpen } = useCancelAgreementStore();

	return (
		<>
			<button onClick={() => setModalOpen(!isModalOpen)}>
				<Arrow stroke={subText} className="-rotate-90" />
			</button>
			<CancelAgreementModal />
		</>
	);
}
