import customAxios from 'api';

type Response = {
	message: string;
};

type Params = {
	id: number;
	max_stroke?: number;
	min_stroke?: number;
	max_detection?: number;
	min_detection?: number;
	max_speed?: number;
	min_speed?: number;
};

export const patchGraph = async (data: Params) => {
	try {
		const response = await customAxios.patch<Response>(
			'/hardware/graphSetting.php',
			data
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};
