import clsx from 'clsx';

export const boxContainer = clsx(
	'rounded-md border bg-white',
	'flex flex-col',
	'divide-y'
);

export const buttonStyle = clsx(
	'border rounded border-sub-color py-3 px-[14px]',
	'flex items-center gap-x-2.5'
);
