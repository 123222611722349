import { useTranslation } from 'react-i18next';
import { buttonStyle } from '.';
import { ReactComponent as CalibrationIcon } from 'asset/icon/calibration.svg';
import { useState } from 'react';
import ConfrimModal from 'components/modal/ConfirmModal';

export default function CalibrationButton() {
	const { t } = useTranslation();
	//modal
	const [isModalOpen, setIsModalOpen] = useState(false);
	return (
		<>
			<button className={buttonStyle} onClick={() => setIsModalOpen(true)}>
				<CalibrationIcon />
				<span className="text-sm font-bold">{t('actuator.calibration')}</span>
			</button>
			{/* Calibration Modal */}
			<ConfrimModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				description={t('actuator.calibration_description')}
			/>
		</>
	);
}
