import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import useAuthStore from 'store/useAuthStore';
import { refreshAccessToken } from './auth/refresh';
import { userLogout } from './auth/userLogout';

const BASEURL = process.env.REACT_APP_API_URL;

export const axiosConfig: AxiosRequestConfig = {
	baseURL: BASEURL,
	headers: {
		'Content-Type': 'application/json',
	},
	withCredentials: true,
};

const customAxios: AxiosInstance = axios.create(axiosConfig);

// Axios 요청 인터셉터 설정
customAxios.interceptors.request.use(
	async (config) => {
		// 요청에 Authorization 헤더가 없는 경우에만 Zustand의 토큰 추가
		if (!config.headers.Authorization) {
			const { accessToken } = useAuthStore.getState();
			if (accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`;
			}
		}
		return config;
	},
	(error) => Promise.reject(error)
);

// Axios 응답 인터셉터 설정
customAxios.interceptors.response.use(
	(response) => response,
	async (error) => {
		if (error.response?.status === 401) {
			const originalRequest = error.config;

			// Access Token 만료 처리
			try {
				const newAccessToken = await refreshAccessToken();

				// Zustand 상태에 Access Token 저장
				useAuthStore.getState().setAccessToken(newAccessToken);

				// 원래 요청에 실패했던 header 업데이트
				originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;

				// 새로운 Access Token으로 다시 요청
				return axios.request(originalRequest);
			} catch (error) {
				// Refresh Token 만료 처리
				useAuthStore.getState().setAccessToken(null);
				await userLogout();
				return Promise.reject(error);
			}
		}

		return Promise.reject(error); // 다른 에러는 그대로 반환
	}
);

export default customAxios;
