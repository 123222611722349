import { userLogout } from 'api/auth/userLogout';
import { userResetPassword } from 'api/reset/userResetPassword';
import userResetPasswordConfirm from 'api/reset/userResetPasswordConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'store/useAuthStore';

export default function usePasswordChange(token: string) {
	const navigate = useNavigate();
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [submitStatus, setSubmitStatus] = useState<
		'error' | 'success' | 'not-exist-token'
	>('success');
	//onSubmit
	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		// Send the reset request
		try {
			if (!token) {
				return 'not-exist-token';
			}

			const result = await userResetPasswordConfirm(
				{ password: newPassword },
				token
			);
			//로그아웃
			await userLogout();

			navigate('/reset-result');
		} catch (error) {
			setSubmitStatus('error');
		}
	};
	return {
		newPassword,
		setNewPassword,
		confirmPassword,
		setConfirmPassword,
		submitStatus,
		onSubmit,
	};
}
