import clsx from 'clsx';
import { ReactComponent as MoconLogo } from 'asset/icon/mocon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useLogin } from 'hook/useLogin';
import LoginResultStatus from './LoginResultStatus';
import SimpleLanguageSelector from 'components/header/language_selector/SimpleLanguageSelector';

export default function LoginBox() {
	const { t } = useTranslation();
	const { onLogin, loginResult } = useLogin();
	return (
		<form className={boxStyle} onSubmit={onLogin}>
			<MoconLogo width={182} />
			<div className="flex flex-col gap-y-[11px]">
				<div className="flex flex-col gap-y-[14px]">
					{/* email input */}
					<input
						name="email"
						type="email"
						placeholder={t('login.email')}
						className="border border-sub-color rounded px-2.5 py-3 w-[400px]"
						defaultValue={'mocon@mocon.com'}
					/>
					{/* password input */}
					<input
						name="password"
						type="password"
						placeholder={t('login.password')}
						className="border border-sub-color rounded px-2.5 py-3 w-[400px]"
						defaultValue={'mocon123123*'}
					/>
				</div>
				{/* 비밀번호를 잊으셨나요 */}
				<div className="flex justify-end">
					<Link to="/forget" className="text-sm underline text-sub-text">
						{t('login.forgot_password')}
					</Link>
				</div>
			</div>
			<div className="flex flex-col gap-y-1">
				<button
					type="submit"
					className="bg-primary-blue text-white rounded px-2.5 py-[14px] w-[400px]"
				>
					<span className="font-bold text-white">{t('login.button')}</span>
				</button>
				<LoginResultStatus status={loginResult} />
			</div>
			<div className="flex gap-x-2.5 items-center text-sm">
				<p>{t('login.dont_have_account')}</p>
				<Link to="/sign-up" className="font-bold text-primary-blue">
					{t('login.signup')}
				</Link>
			</div>
			<div className="absolute bottom-1 right-1">
				<SimpleLanguageSelector />
			</div>
		</form>
	);
}

//style
const boxStyle = clsx(
	'bg-white border border-[#EEEEEE] rounded-[10px] relative',
	'px-[25px] py-[45px]',
	'flex flex-col gap-y-[30px] items-center'
);
