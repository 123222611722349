import { useMotorSettingsQuery } from 'query/useMotorSettingsQuery';
import CloseLimitOpenHallButton from './CloseLimitOpenHallButton';
import OnlyHallButton from './OnlyHallButton';
import OpenLimitCloseHallButton from './OpenLimitCloseHallButton';
import useSelectedActuatorStore from 'store/useSelectedActuatorStore';
import { useEffect } from 'react';
import useActuatorValueStore from 'store/useActuatorValueStore';

export default function SensorSettingArea() {
	const { selectedActuatorNumber } = useSelectedActuatorStore();
	const { data: motorSettings } = useMotorSettingsQuery();
	const { setSensorSettings } = useActuatorValueStore();
	const selectedMotorSetting = motorSettings?.find(
		(motorSetting) => motorSetting.actuator_number === selectedActuatorNumber
	);
	useEffect(() => {
		if (!selectedMotorSetting) return;

		if (selectedMotorSetting.sensor_setting === 1) {
			setSensorSettings('only-hall');
		} else if (selectedMotorSetting.sensor_setting === 2) {
			setSensorSettings('open-limit-close-hall');
		} else if (selectedMotorSetting.sensor_setting === 3) {
			setSensorSettings('close-limit-open-hall');
		} else {
			setSensorSettings('none');
		}
	}, [selectedMotorSetting]);
	return (
		<div className="flex items-center gap-x-4">
			<OnlyHallButton />
			<OpenLimitCloseHallButton />
			<CloseLimitOpenHallButton />
		</div>
	);
}
