import { useTranslation } from 'react-i18next';
import { buttonStyle } from '.';
import { ReactComponent as ImportIcon } from 'asset/icon/import.svg';
import { checkAccess } from 'api/auth/checkAccess';

export default function PresetImportButton() {
	const { t } = useTranslation();

	const onClick = async () => {
		try {
			const response = await checkAccess();
			console.log(response);
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<button className={buttonStyle} onClick={onClick}>
			<ImportIcon />
			<span className="text-sm font-bold">{t('actuator.preset_import')}</span>
		</button>
	);
}
