import {
	useEditMotorMutation,
	useMotorSettingsQuery,
} from 'query/useMotorSettingsQuery';
import useSelectedActuatorStore from 'store/useSelectedActuatorStore';

export const useMotorSetting = () => {
	const { selectedActuatorNumber } = useSelectedActuatorStore();
	const { data: MotorSettings } = useMotorSettingsQuery(
		selectedActuatorNumber || undefined
	);
	const { mutateAsync: editMotorSetting } = useEditMotorMutation();
	const selectedMotorSetting = MotorSettings && MotorSettings[0];

	//stroke
	const stroke = selectedMotorSetting?.stroke || 0;
	const minStroke = selectedMotorSetting?.min_stroke || 0;
	const maxStroke = selectedMotorSetting?.max_stroke || 100;
	const onSubmitStroke = async (
		newMinStroke: number,
		newMaxStroke: number,
		newStroke: number
	) => {
		//patch
		if (!selectedMotorSetting) return;
		// 세 값이 기존 값과 같으면 patch를 호출하지 않는다.
		if (
			newMinStroke === selectedMotorSetting.min_stroke &&
			newMaxStroke === selectedMotorSetting.max_stroke &&
			newStroke === selectedMotorSetting.stroke
		) {
			return;
		}
		await editMotorSetting({
			id: selectedMotorSetting.id,
			min_stroke: newMinStroke,
			max_stroke: newMaxStroke,
			stroke: newStroke,
		});
	};

	//detection
	const detection = selectedMotorSetting?.detection || 0;
	const onSubmitDetection = async (newDetection: number) => {
		if (!selectedMotorSetting) return;
		if (newDetection === selectedMotorSetting.detection) return;
		await editMotorSetting({
			id: selectedMotorSetting.id,
			detection: newDetection,
		});
	};

	//speed
	const speed = selectedMotorSetting?.speed || 0;
	const onSubmitSpeed = async (newSpeed: number) => {
		if (!selectedMotorSetting) return;
		if (newSpeed === selectedMotorSetting.speed) return;
		await editMotorSetting({
			id: selectedMotorSetting.id,
			speed: newSpeed,
		});
	};

	return {
		selectedMotorSetting,
		stroke,
		detection,
		speed,
		minStroke,
		maxStroke,
		onSubmitStroke,
		onSubmitDetection,
		onSubmitSpeed,
	};
};
