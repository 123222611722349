import Modal from 'react-modal';
import { customModalStyles } from '../style';
import { useTranslation } from 'react-i18next';
import CommunicationStatus from './CommunicationStatus';
import PortSelect from './select/PortSelect';
import ParitySelect from './select/ParitySelect';
import SpeedSelect from './select/SpeedSelect';
import StopBitsSelect from './select/StopBitsSelect';
import DataSelect from './select/DataSelect';
import FlowControlSelect from './select/FlowControlSelect';
import MsecCharInput from './input/MsecCharInput';
import MsecLineInput from './input/MsecLineInput';
import ModalCancelButton from './button/ModalCancelButton';
import ModalConfirmButton from './button/ModalConfirmButton';

import CloseCrossButton from '../CloseCrossButton';
type Props = {
	isOpen: boolean;
	onClose: () => void;
};

export default function CommunicationModal({ isOpen, onClose }: Props) {
	const { t } = useTranslation();
	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onClose}
			style={customModalStyles}
			className={'max-w-[658px] p-6'}
		>
			<div className="flex flex-col gap-y-5">
				<header className="flex items-center justify-between gap-x-5">
					<div className="flex items-center gap-x-5">
						<h2 className="font-bold">{t('communication.modal_title')}</h2>
						<CommunicationStatus />
					</div>
					<CloseCrossButton onClick={onClose} />
				</header>
				{/* contents */}
				<div className="grid grid-cols-2 gap-x-5 gap-y-5">
					<div className="flex items-center justify-between">
						<label className="text-sm font-bold">
							{t('communication.port')}
						</label>
						<PortSelect />
					</div>
					<div className="flex items-center justify-between">
						<label className="text-sm font-bold">
							{t('communication.parity')}
						</label>
						<ParitySelect />
					</div>
					<div className="flex items-center justify-between">
						<label className="text-sm font-bold">
							{t('communication.speed')}
						</label>
						<SpeedSelect />
					</div>
					<div className="flex items-center justify-between">
						<label className="text-sm font-bold">
							{t('communication.stop_bit')}
						</label>
						<StopBitsSelect />
					</div>
					<div className="flex items-center justify-between">
						<label className="text-sm font-bold">
							{t('communication.data')}
						</label>
						<DataSelect />
					</div>
					<div className="flex items-center justify-between">
						<label className="text-sm font-bold">
							{t('communication.flow_control')}
						</label>
						<FlowControlSelect />
					</div>
				</div>
				{/* Transmission delay */}
				<div
					id="transmission-delay-box"
					className="rounded-[10px] border-sub-color border p-5 flex flex-col gap-y-2.5"
				>
					<p className="font-bold">{t('communication.transmission_delay')}</p>
					<div className="flex flex-1 gap-x-[30px] items-center">
						<MsecCharInput />
						<MsecLineInput />
					</div>
				</div>
				<div id="communication-modal-buttons" className="flex gap-x-[15px]">
					<ModalCancelButton onClose={onClose} />
					<ModalConfirmButton onClose={onClose} />
				</div>
			</div>
		</Modal>
	);
}
