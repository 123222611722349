import { userLogout } from 'api/auth/userLogout';
import userResetPasswordConfirm from 'api/reset/userResetPasswordConfirm';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useResetStore from 'store/useResetStore';
import { checkPasswordFormat } from 'utils/validation';

export default function useReset() {
	const {
		password,
		passwordConfirmation,
		reset,
		submitStatus,
		setSubmitStatus,
	} = useResetStore();
	const active =
		checkPasswordFormat(password) && password === passwordConfirmation;
	//react-router-dom: url에서 tokenId를 가져온다.
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const tokenId = searchParams.get('tokenId');

	const onSubmit = useCallback(
		async (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			// Send the reset request
			try {
				if (!tokenId) {
					return setSubmitStatus('not-exist-token');
				}
				const result = await userResetPasswordConfirm({ password }, tokenId);
				//로그아웃
				reset();
				await userLogout();

				navigate('/reset-result');
			} catch (error) {
				setSubmitStatus('error');
			}
		},
		[tokenId, password, reset, setSubmitStatus]
	);

	return {
		active,
		onSubmit,
		submitStatus,
	};
}
