import NumberSpinner from 'components/actuator/value_control/NumberSpinner';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { cancelStyle, confirmStyle, customModalStyles } from '../style';
import { ReactComponent as SaveIcon } from 'asset/icon/save.svg';
import clsx from 'clsx';
type Props = {
	isOpen: boolean;
	onClose: () => void;
};
export default function HoleCounterSettingModal({ isOpen, onClose }: Props) {
	const { t } = useTranslation();
	const [hole, setHole] = useState<number>(5);
	return (
		<ReactModal
			isOpen={isOpen}
			style={customModalStyles}
			className={clsx('p-6 w-[350px]')}
		>
			<div className="flex flex-col gap-y-5 ">
				<h4 className="text-lg font-bold">
					{t('actuator.hole_counter.modal_title')}
				</h4>
				<div className="flex gap-x-[30px] items-center justify-between">
					<span className="font-bold whitespace-nowrap">
						{t('actuator.hole_counter.hole_counter')}
					</span>
					<div className="flex gap-x-2.5 items-center">
						<input
							type="number"
							value={hole}
							className="border  border-sub-color w-[70px] px-2.5 flex items-center justify-center h-[35px]"
							onChange={(e) => {
								const newValue = parseInt(e.target.value);
								if (isNaN(newValue)) return setHole(0);
								setHole(newValue);
							}}
							onBlur={() => {
								if (hole < 0) setHole(0);
							}}
						/>
						<NumberSpinner value={hole} setValue={setHole} min={0} max={100} />
					</div>
				</div>
				<div id="hole-counter-modal-buttons" className="flex gap-x-[15px]">
					<button id="cancel-button" onClick={onClose} className={cancelStyle}>
						{t('actuator.hole_counter.cancel')}
					</button>
					<button id="save-button" onClick={onClose} className={confirmStyle}>
						<SaveIcon fill="white" />
						<span>{t('actuator.hole_counter.save')}</span>
					</button>
				</div>
			</div>
		</ReactModal>
	);
}
