import { useTranslation } from 'react-i18next';

type Props = {
	actuatorNumber: number;
	onApply: () => void;
};

export default function ApplyActuatorButton({
	actuatorNumber,
	onApply,
}: Props) {
	const { t } = useTranslation();
	const handleClick = () => {
		onApply();
	};
	return (
		<button
			className="flex items-center justify-center flex-1 rounded bg-primary-blue"
			onClick={handleClick}
		>
			<span className="font-bold text-white">
				{t('actuator.select_modal.apply_button', { number: actuatorNumber })}
			</span>
		</button>
	);
}
