import customAxios from 'api';

type Response = {
	email: string;
	name: string;
	company_name: string;
	contact_information: string;
};

// 프로필 정보 가져오기
export const getProfile = async () => {
	try {
		const response = await customAxios.get<Response>('/user/userProfile.php');
		return response.data;
	} catch (error) {
		throw error;
	}
};
