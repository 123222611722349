// import Header from 'components/header/Header';
import ProfileBox from 'components/profile/ProfileBox';

export default function ProfilePage() {
	return (
		<div className="">
			{/* <Header />
			<div className="padding-header-height" /> */}
			<main className="flex flex-col items-center justify-center w-screen h-screen py-6">
				<ProfileBox />
			</main>
		</div>
	);
}
