import { useEffect, useRef } from 'react';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	className?: string; // 스타일링 클래스 지원
};

export default function DropDown({
	isOpen,
	onClose,
	children,
	className,
}: Props) {
	const dropdownRef = useRef<HTMLDivElement | null>(null);

	// 외부 클릭 감지하여 닫기
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target as Node)
			) {
				onClose();
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [onClose]);

	// 드롭다운이 닫힌 상태에서는 렌더링하지 않음
	if (!isOpen) return null;

	return (
		<div
			ref={dropdownRef} // Ref 연결
			className={`absolute right-0 top-full  mt-2.5 bg-white border rounded border-sub-color ${className}`}
			role="menu" // ARIA 접근성
			aria-hidden={!isOpen}
		>
			{children}
		</div>
	);
}
