import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { MOTOR_SETTING_KEY } from './keys';
import { getMotorSetting } from '../api/motor_setting/getMotorSetting';
import { postMotorSetting } from 'api/motor_setting/postMotorSetting';
import { deleteMotorSetting } from 'api/motor_setting/deleteMotorSetting';
import { patchMotorSetting } from 'api/motor_setting/patchMotorSetting';

// 모터 설정을 가져오는 쿼리
export const useMotorSettingsQuery = (actuatorNumber?: number) => {
	return useQuery({
		queryKey: [MOTOR_SETTING_KEY, actuatorNumber],
		queryFn: async () => {
			const result = await getMotorSetting(actuatorNumber);
			return result;
		},
	});
};

//모터 설정을 추가하는 뮤테이션
export const useAddMotorMutation = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: postMotorSetting,
		onSuccess: () => {
			queryClient.invalidateQueries([MOTOR_SETTING_KEY]);
		},
	});
};

//모터 설정을 삭제하는 뮤테이션
export const useDeleteMotorMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: deleteMotorSetting,
		onSuccess: () => {
			queryClient.invalidateQueries([MOTOR_SETTING_KEY]);
		},
	});
};

//모터 설정을 수정하는 뮤테이션
export const useEditMotorMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: patchMotorSetting,
		onSuccess: () => {
			queryClient.invalidateQueries([MOTOR_SETTING_KEY]);
		},
	});
};
