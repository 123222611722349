import { create } from 'zustand';

interface CancelAgreementState {
	isAgreed: boolean;
	isModalOpen: boolean;
	setAgreement: (agreed: boolean) => void;
	setModalOpen: (open: boolean) => void;
}

const useCancelAgreementStore = create<CancelAgreementState>((set) => ({
	isAgreed: false,
	isModalOpen: false,
	setAgreement: (agreed) => set({ isAgreed: agreed }),
	setModalOpen: (open) => set({ isModalOpen: open }),
}));

export default useCancelAgreementStore;
