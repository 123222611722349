import { useTranslation } from 'react-i18next';
import { emailConfirmStyle, inputStyle, labelStyle } from '.';
import { useSignup } from 'hook/useSignup';
import useSignupStore from 'store/useSignupStore';
import clsx from 'clsx';
import { isValidateEmail } from 'utils/validation';

export default function EmailField() {
	const { t } = useTranslation();
	const { onSubmitEmail } = useSignup();
	const { emailCheckedStatus, email, setEmail } = useSignupStore();
	return (
		<div id="signup-email-field" className="relative flex flex-col gap-y-2">
			<label htmlFor="email" className={labelStyle}>
				{t('signup.email_label')}
			</label>
			<div className="flex gap-x-[14px] items-center">
				<input
					className={inputStyle}
					type="email"
					id="email"
					name="email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
					placeholder={t('signup.email_placeholder')}
				/>
				<button
					type="button"
					onClick={onSubmitEmail}
					className={emailConfirmStyle}
					disabled={!isValidateEmail(email)}
				>
					{t('signup.email_confirm')}
				</button>
			</div>
			<p className={getEmailCheckedStatusStyle(emailCheckedStatus)}>
				{emailCheckedStatus === 'success'
					? t('signup.email_duplicate_success')
					: emailCheckedStatus === 'duplicate'
					? t('signup.email_duplicate_fail')
					: emailCheckedStatus === 'fail'
					? t('signup.email_fail')
					: ''}
			</p>
		</div>
	);
}

// style
const getEmailCheckedStatusStyle = (status: string) =>
	clsx('text-sm', {
		'text-green-500': status === 'success',
		'text-red-500': status === 'duplicate' || status === 'fail',
	});
