import {
	getInitialsPosition,
	getInitialsSpeed,
} from 'utils/actuator/variableSpeed';
import { create } from 'zustand';

interface ActuatorValueState {
	stroke: number;
	detection: number;
	speed: number;
	minStroke: number;
	maxStroke: number;
	speedMode: 'single' | 'variable';
	strokeIntervalsCount: number; // 스트로크 구간 개수
	speedArray: number[]; // 구간별속도배열
	positionArray: number[]; // 구간별포지션배열
	switchMode: 'push' | 'push-lock' | null;
	sensorSettings:
		| 'only-hall'
		| 'open-limit-close-hall'
		| 'close-limit-open-hall'
		| 'none';
	setStroke: (stroke: number) => void;
	setDetection: (detection: number) => void;
	setSpeed: (speed: number) => void;
	setMinStroke: (minStroke: number) => void;
	setMaxStroke: (maxStroke: number) => void;
	setSpeedMode: (speedMode: 'single' | 'variable') => void;
	setStrokeIntervalsCount: (strokeIntervalsCount: number) => void;
	setSpeedArray: (speedArray: number[]) => void;
	setPositionArray: (positionArray: number[]) => void;
	setSwitchMode: (switchMode: 'push' | 'push-lock' | null) => void;
	setSensorSettings: (
		sensorSettings:
			| 'only-hall'
			| 'open-limit-close-hall'
			| 'close-limit-open-hall'
			| 'none'
	) => void;
}

const useActuatorValueStore = create<ActuatorValueState>((set, get) => ({
	stroke: 0,
	detection: 30,
	speed: 0,
	minStroke: 0,
	maxStroke: 130,
	speedMode: 'variable',
	strokeIntervalsCount: 5,
	speedArray: getInitialsSpeed(5),
	positionArray: getInitialsPosition(5),
	switchMode: null,
	sensorSettings: 'none',
	setStroke: (stroke) => set({ stroke }),
	setDetection: (detection) => set({ detection }),
	setSpeed: (speed) => set({ speed }),
	setMinStroke: (minStroke) => set({ minStroke }),
	setMaxStroke: (maxStroke) => set({ maxStroke }),
	setSpeedMode: (speedMode) => set({ speedMode }),
	setStrokeIntervalsCount: (strokeIntervalsCount) =>
		set({ strokeIntervalsCount }),
	setSpeedArray: (speedArray) => set({ speedArray }),
	setPositionArray: (positionArray) => set({ positionArray }),
	setSwitchMode: (switchMode) => set({ switchMode }),
	setSensorSettings: (sensorSettings) => set({ sensorSettings }),
}));

export default useActuatorValueStore;
