import { inputStyle, labelStyle } from 'components/signup';
import { useTranslation } from 'react-i18next';
import useResetStore from 'store/useResetStore';
import MatchedState from './MatchedState';

export default function PasswordConfirm() {
	const { t } = useTranslation();
	const { passwordConfirmation, setPasswordConfirmation } = useResetStore();

	return (
		<div id="reset-password-confirm" className="flex flex-col gap-y-2">
			<label htmlFor="password" className={labelStyle}>
				{t('reset.password_confirm_label')}
			</label>
			<input
				className={inputStyle}
				type="password"
				id="password"
				placeholder={t('reset.password_confirm_placeholder')}
				value={passwordConfirmation}
				onChange={(e) => setPasswordConfirmation(e.target.value)}
			/>
			<MatchedState />
		</div>
	);
}
