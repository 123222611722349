import { create } from 'zustand';

interface OperationGraphState {
	minStroke: number;
	maxStroke: number;
	minDetection: number;
	maxDetection: number;
	minSpeed: number;
	maxSpeed: number;
	setMinStroke: (value: number) => void;
	setMaxStroke: (value: number) => void;
	setMinDetection: (value: number) => void;
	setMaxDetection: (value: number) => void;
	setMinSpeed: (value: number) => void;
	setMaxSpeed: (value: number) => void;
	isStrokeActive: boolean;
	setIsStrokeActive: (value: boolean) => void;
	isDetectionActive: boolean;
	setIsDetectionActive: (value: boolean) => void;
	isSpeedActive: boolean;
	setIsSpeedActive: (value: boolean) => void;
}

const useOperationGraphStore = create<OperationGraphState>((set) => ({
	minStroke: 0,
	maxStroke: 200,
	minDetection: 0,
	maxDetection: 100,
	minSpeed: 0,
	maxSpeed: 100,
	setMinStroke: (value) => set({ minStroke: value }),
	setMaxStroke: (value) => set({ maxStroke: value }),
	setMinDetection: (value) => set({ minDetection: value }),
	setMaxDetection: (value) => set({ maxDetection: value }),
	setMinSpeed: (value) => set({ minSpeed: value }),
	setMaxSpeed: (value) => set({ maxSpeed: value }),

	isStrokeActive: true,
	setIsStrokeActive: (value) => set({ isStrokeActive: value }),
	isDetectionActive: true,
	setIsDetectionActive: (value) => set({ isDetectionActive: value }),
	isSpeedActive: true,
	setIsSpeedActive: (value) => set({ isSpeedActive: value }),
}));

export default useOperationGraphStore;
