import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthStore from 'store/useAuthStore';

type PrivateRouteProps = {
	children: React.ReactNode;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
	const { accessToken, isAuthInitializing } = useAuthStore();

	if (isAuthInitializing) {
		// 초기화 중일 때 아무것도 렌더링하지 않음
		return null;
	}

	// accessToken이 없으면 로그인 페이지로 리다이렉트
	if (!accessToken) {
		return <Navigate to="/" replace />;
	}

	// 인증된 경우 자식 컴포넌트를 렌더링
	return <>{children}</>;
};

export default PrivateRoute;
