import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Password from './Password';
import PasswordConfirm from './PasswordConfirm';
import ResetConfirmButton from './ResetConfirmButton';
import useReset from 'hook/useReset';

export default function ResetForm() {
	const { t } = useTranslation();
	// Reset the password and passwordConfirmation fields
	const { onSubmit } = useReset();
	return (
		<form className={boxStyle} onSubmit={onSubmit}>
			<h1 className="text-lg font-semibold">{t('reset.title')}</h1>
			<div className="flex flex-col gap-y-4">
				<div className="flex flex-col gap-y-[30px]">
					<Password />
					<PasswordConfirm />
				</div>
				<ResetConfirmButton />
			</div>
		</form>
	);
}

//style
const boxStyle = clsx(
	'bg-white border border-[#EEEEEE] rounded-[10px]',
	'p-6',
	'flex flex-col gap-y-[50px] items-center'
);
