import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function CommunicationStatus() {
	const { t } = useTranslation();
	let status: 'normal' | 'error' | 'waiting' = 'normal';

	//status을 랜덤하게 세 상태중 하나로 변경하기
	const random = Math.random();
	if (random < 0.33) {
		status = 'normal';
	} else if (random < 0.66) {
		status = 'error';
	} else {
		status = 'waiting';
	}

	return (
		<div
			className={clsx(
				'w-[100px] rounded px-2 py-1.5 flex items-center justify-center whitespace-nowrap',
				{
					'bg-warn-red': status === 'error',
					'bg-[#FFAE00]': status === 'waiting',
					'bg-[#66D703]': status === 'normal',
				}
			)}
		>
			<span className="text-white font-bold text-xs">
				{status === 'normal' && t('communication.status.normal')}
				{status === 'error' && t('communication.status.error')}
				{status === 'waiting' && t('communication.status.waiting')}
			</span>
		</div>
	);
}
