import ActuatorSelector from 'components/actuator/actuator_selector/ActuatorSelector';
import ActuatorBox from 'components/actuator/ActuatorBox';
import AsyncBoundary from 'components/common/AsyncBoundary';
import Header from 'components/header/Header';

export default function ActuatorPage() {
	return (
		<div>
			<Header />
			<div className="padding-header-height" />
			<main className="flex flex-col items-center px-4 py-6">
				<div className="flex flex-col gap-y-[14px]">
					<AsyncBoundary SuspenseFallback={<div className="h-[45px]" />}>
						<ActuatorSelector />
					</AsyncBoundary>
					<ActuatorBox />
				</div>
			</main>
		</div>
	);
}
