import { useTranslation } from 'react-i18next';
import { buttonStyle } from '.';
import useOperationGraphStore from 'store/useOperationGraphStore';
import { strokeColor } from '..';
import { ReactComponent as CheckIcon } from 'asset/icon/legend-check.svg';

export default function StrokeLegendButton() {
	const { t } = useTranslation();
	const { isStrokeActive, setIsStrokeActive } = useOperationGraphStore();

	return (
		<button
			className={buttonStyle}
			onClick={() => setIsStrokeActive(!isStrokeActive)}
		>
			<div
				className="w-[14px] h-[14px] rounded-sm flex items-center justify-center"
				style={{
					backgroundColor: isStrokeActive ? strokeColor : 'white',
					border: isStrokeActive ? 'none' : `1px solid ${strokeColor}`,
				}}
			>
				{isStrokeActive && <CheckIcon />}
			</div>
			{t('actuator.operation_graph.strokes')}
		</button>
	);
}
