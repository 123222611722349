import clsx from 'clsx';
import { inputStyle, labelStyle } from 'components/signup';
import { useTranslation } from 'react-i18next';
import useResetStore from 'store/useResetStore';
import { checkPasswordFormat } from 'utils/validation';

export default function Password() {
	const { t } = useTranslation();
	const { password, setPassword } = useResetStore();
	const isValidPassword = checkPasswordFormat(password);
	return (
		<div id="reset-password" className="flex flex-col gap-y-2">
			<label htmlFor="password" className={labelStyle}>
				{t('reset.password_label')}
			</label>
			<input
				className={inputStyle}
				type="password"
				id="password"
				placeholder={t('reset.password_placeholder')}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
			/>
			<p className={getConditionStyle(isValidPassword, password.length)}>
				{t('reset.password_condition')}
			</p>
		</div>
	);
}

//style
const getConditionStyle = (isValid: boolean, length: number) =>
	clsx('text-sm ', {
		'text-red-500': !isValid,
		'text-sub-text': isValid || length === 0,
	});
