import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from 'asset/icon/cross.svg';
import { subText } from 'utils/color';

import { useActuatorSelect } from 'hook/useActuatorSelect';
import ConfrimModal from 'components/modal/ConfirmModal';
type Props = {
	actuatorNumber: number;
	index: number;
};
export default function ActuatorItem({ actuatorNumber, index }: Props) {
	const { t } = useTranslation();
	const {
		isSelected,
		onDelete,
		setSelectedActuatorNumber,
		isDelectModalOpen,
		setDeleteModalOpen,
	} = useActuatorSelect(actuatorNumber);

	return (
		<>
			<button
				className={getButtonStyle(isSelected)}
				onClick={() => setSelectedActuatorNumber(actuatorNumber)}
			>
				<span className="whitespace-nowrap">
					{t('actuator.actuator_number', { number: index + 1 })}
				</span>
				<button
					onClick={(e) => {
						e.stopPropagation();
						setDeleteModalOpen(true);
					}}
				>
					<CrossIcon stroke={isSelected ? 'white' : subText} />
				</button>
			</button>
			<ConfrimModal
				isOpen={isDelectModalOpen}
				onClose={() => setDeleteModalOpen(false)}
				onConfirm={onDelete}
				description={t('actuator.delete_description')}
			/>
		</>
	);
}
// style
const getButtonStyle = (selected: boolean) =>
	clsx(
		'w-[140px] h-[45px] p-[14px] rounded-md',
		'flex gap-x-5 items-center justify-center',
		'font-semibold',
		{
			'bg-primary-blue text-white': selected,
			'bg-white text-sub-text border border-sub-color': !selected,
		}
	);
