import { useTranslation } from 'react-i18next';
import { buttonStyle } from '..';
import { ReactComponent as Cycle } from 'asset/icon/play-cycle.svg';
import ConfrimModal from 'components/modal/ConfirmModal';
import { useState } from 'react';
export default function ResetSettingsButton() {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const handleOpen = () => {
		setIsOpen(true);
	};

	return (
		<>
			<button className={buttonStyle} onClick={handleOpen}>
				<Cycle />
				<span>{t('header.settings')}</span>
			</button>
			<ConfrimModal
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				description={t('confirm.reset_message')}
			/>
		</>
	);
}
