import { useProfileQuery } from 'query/useProfileQuery';

type Props = {
	type: 'email' | 'name' | 'companyName' | 'contact';
};

export default function ProfileText({ type }: Props) {
	const { data: profile } = useProfileQuery();
	const email = profile?.email;
	const name = profile?.name;
	const companyName = profile?.company_name;
	const contactInformation = profile?.contact_information;

	switch (type) {
		case 'email':
			return <span className={valueStyle}>{email}</span>;
		case 'name':
			return <span className={valueStyle}>{name}</span>;
		case 'companyName':
			return <span className={valueStyle}>{companyName}</span>;
		case 'contact':
			return <span className={valueStyle}>{contactInformation}</span>;
		default:
			return null;
	}
}
const valueStyle = 'text-sm text-right';
