import { contentsStyle, labelStyle } from './style';

export default function ForceSpec() {
	const push = 1200;
	const pull = 1200;
	const selfLocking = 1200;
	return (
		<div className="flex flex-col flex-1 gap-y-2">
			<span className={labelStyle}>Force</span>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Push:</span>
				<span className={contentsStyle}>{push}</span>
			</div>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Pull:</span>
				<span className={contentsStyle}>{pull}</span>
			</div>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Self-Locking:</span>
				<span className={contentsStyle}>{selfLocking}</span>
			</div>
		</div>
	);
}
