import clsx from 'clsx';

export const labelStyle = clsx('font-bold');
export const inputStyle = clsx(
	'border py-3 px-2.5 rounded flex-1 border-sub-color'
);
export const emailConfirmStyle = clsx(
	'border py-3 px-5 rounded border-sub-color',
	'text-sm font-bold',
	'disabled:opacity-50'
);
