import { refreshAccessToken } from 'api/auth/refresh';
import { create } from 'zustand';

type AuthState = {
	accessToken: string | null;
	setAccessToken: (token: string | null) => void;
	initializeAuth: () => Promise<void>;
	isAuthInitializing: boolean; // 초기화 상태 추가
};

const useAuthStore = create<AuthState>((set) => ({
	accessToken: null,
	isAuthInitializing: true, // 앱이 처음 로드될 때 초기화 상태

	setAccessToken: (token) => set({ accessToken: token }),
	initializeAuth: async () => {
		try {
			// Refresh Token을 사용해 Access Token 발급
			const newAccessToken = await refreshAccessToken();
			set({ accessToken: newAccessToken, isAuthInitializing: false }); // 초기화 완료
		} catch (error) {
			console.error('Failed to initialize authentication:', error);
			set({ accessToken: null, isAuthInitializing: false }); // 로그아웃 처리
		}
	},
}));

export default useAuthStore;
