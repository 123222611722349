import {
	useGraphSettingsMutation,
	useGraphSettingsQuery,
} from 'query/useGraphSettingsQuery';
import { useState } from 'react';

export const useDetectionSettings = () => {
	const { data: graphSetting } = useGraphSettingsQuery();
	const { mutateAsync: setGraphSettings } = useGraphSettingsMutation();

	const id = graphSetting?.id ?? 0;
	const minDetection = graphSetting?.min_detection ?? 0;
	const maxDetection = graphSetting?.max_detection ?? 100;

	const [minDetectionValue, setMinDetectionValue] = useState<string>(
		`${minDetection}`
	);
	const [maxDetectionValue, setMaxDetectionValue] = useState<string>(
		`${maxDetection}`
	);

	const onChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) < 0) {
			e.target.value = `${minDetectionValue}`;
			return;
		}
		setMinDetectionValue(value);
	};

	const onChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) < 0) {
			e.target.value = `${maxDetectionValue}`;
			return;
		}
		setMaxDetectionValue(value);
	};

	const onSubmit = () => {
		try {
			if (Number(minDetectionValue) < Number(maxDetectionValue)) {
				setGraphSettings({
					id,
					min_detection: Number(minDetectionValue),
					max_detection: Number(maxDetectionValue),
				});
			} else {
				setGraphSettings({
					id,
					min_detection: Number(maxDetectionValue),
					max_detection: Number(minDetectionValue),
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const disabled =
		(minDetectionValue === `${minDetection}` &&
			maxDetectionValue === `${maxDetection}`) ||
		minDetectionValue === '' ||
		maxDetectionValue === '' ||
		Number(minDetectionValue) > Number(maxDetectionValue);

	return {
		id,
		minDetection,
		maxDetection,
		minDetectionValue,
		maxDetectionValue,
		onChangeMin,
		onChangeMax,
		onSubmit,
		disabled,
	};
};
