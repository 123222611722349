import { useCallback, useEffect, useState } from 'react';
import {
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import useActuatorValueStore from 'store/useActuatorValueStore';

export default function VariableSpeedGraph() {
	const {
		positionArray,
		setPositionArray,
		speedArray,
		setSpeedArray,
		maxStroke,
	} = useActuatorValueStore();

	// 그래프 상에서 표시될 data 상태값
	const [data, setData] = useState<
		{ id: number; speed: number; position: number }[]
	>([]);

	const [isDragging, setIsDragging] = useState(false);
	const [draggedPointId, setDraggedPointId] = useState<number | null>(null); // 드래그 중인 dot의 id

	// positionArray와 speedArray를 이용하여 data를 생성
	useEffect(() => {
		const newData = positionArray.map((position, index) => ({
			id: index,
			position,
			speed: speedArray[index],
		}));
		setData(newData);
	}, [positionArray, speedArray]);

	// 드래그 시작
	const handleMouseDown = useCallback((id: number) => {
		setIsDragging(true);
		setDraggedPointId(id);
	}, []);

	const handleMouseMove = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();
			if (isDragging && draggedPointId !== null) {
				const svg = e.currentTarget.getBoundingClientRect();
				const x = Math.min(Math.max(0, e.clientX - svg.left), svg.width); // svg 내에서의 x 좌표
				const y = Math.min(Math.max(0, e.clientY - svg.top), svg.height); // svg 내에서의 y 좌표

				// X, Y 좌표를 데이터의 position, speed 값으로 변환
				const newPosition = Math.round((x / svg.width) * 100) - 3; // 0-100 비율로 변환
				const newSpeed = Math.round(100 - (y / svg.height) * 100); // 0-100 비율로 변환

				//마지막 인덱스의 경우, position은 항상 100으로 고정
				if (draggedPointId === data.length - 1) {
					setData((prev) =>
						prev.map((point) =>
							point.id === draggedPointId
								? { ...point, speed: newSpeed }
								: point
						)
					);
					return;
				}
				//첫번째 인덱스의 경우, position은 항상 0으로 고정
				if (draggedPointId === 0) {
					setData((prev) =>
						prev.map((point) =>
							point.id === draggedPointId
								? { ...point, speed: newSpeed }
								: point
						)
					);
					return;
				}
				setData((prev) =>
					prev.map((point) =>
						point.id === draggedPointId
							? { ...point, position: newPosition, speed: newSpeed }
							: point
					)
				);
			}
		},
		[draggedPointId, isDragging]
	);

	const handleMouseUp = useCallback(
		(e: React.MouseEvent) => {
			e.preventDefault();
			if (isDragging) {
				const updatedPositions = data.map((d) => d.position);
				const updatedSpeeds = data.map((d) => d.speed);

				setPositionArray(updatedPositions); // 첫번째 데이터는 0, 0이므로 제외
				setSpeedArray(updatedSpeeds);

				setIsDragging(false);
				setDraggedPointId(null);
			}
		},
		[data, isDragging, setPositionArray, setSpeedArray]
	);
	// Dot 커스터마이징
	const renderDot = useCallback(
		(props: any) => {
			const { cx, cy, payload } = props;
			return (
				<circle
					cx={cx}
					cy={cy}
					r={8}
					fill="#42C67E"
					stroke="#fff"
					strokeWidth={2}
					onMouseDown={() => handleMouseDown(payload.id)}
					style={{ cursor: 'grab' }}
				/>
			);
		},
		[handleMouseDown]
	);

	return (
		<div
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseUp}
			className="relative w-full h-full"
		>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					width={700}
					height={224}
					data={data}
					margin={{
						top: 0,
						right: 0,
						left: 10,
						bottom: 0,
					}}
					{...{ overflow: 'visible' }}
				>
					<XAxis
						dataKey="position"
						type="number"
						domain={[0, 100]}
						tickCount={11}
						interval={0}
						tickLine={false}
						offset={20}
						className="text-[9px] text-sub-text select-none"
						tickMargin={6}
						label={{
							value: 'Position(%)',
							position: 'left',
							offset: 4,
						}}
					/>

					<YAxis
						type="number"
						yAxisId={'left-speed'}
						orientation="left"
						dataKey={'speed'}
						width={24}
						className="text-[9px] select-none"
						tickCount={11}
						interval={0}
						tickLine={false}
						domain={[0, 100]}
						label={{
							value: 'Speed',
							position: 'top',
							offset: 10,
						}}
					/>
					{/* 추가된 Stroke XAxis */}
					<XAxis
						dataKey="stroke"
						type="number"
						domain={[0, 130]}
						tickCount={11}
						interval={0}
						tickLine={false}
						offset={-10}
						className="text-[9px] text-sub-text select-none"
						tickMargin={6}
						label={{
							value: 'Stroke (mm)',
							position: 'top',
							offset: 0,
						}}
					/>
					<Tooltip cursor={false} />
					<Line
						type="monotone"
						dataKey="speed"
						stroke="#42C67E"
						yAxisId={'left-speed'}
						dot={renderDot}
						isAnimationActive={false}
					/>
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
}
