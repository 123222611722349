import clsx from 'clsx';
import { ReactComponent as Stop } from 'asset/icon/stop-square.svg';

export default function ActuatorStopButton() {
	const available = true;
	return (
		<button className={container} disabled={!available}>
			<Stop fill={available ? '#414141' : '#E0E0E0'} />
		</button>
	);
}

// style
const container = clsx('w-[42px] h-[42px] rounded p-2.5');
