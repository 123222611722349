import { useTranslation } from 'react-i18next';
import ApplyActuatorButton from './ApplyActuatorButton';
import ForceSpec from './specification/ForceSpec';
import LengthSpec from './specification/LengthSpec';
import InputVoltageSpec from './specification/InputVoltageSpec';
import HallSensorSpec from './specification/HallSensorSpec';
import { useMotorInfoQuery } from 'query/useMotorInfoQuery';
import useSelectedMotorModelStore from 'store/useSelectedMotorModel';

type Props = {
	onClose: () => void;
};
export default function SelectedActuatorArea({ onClose }: Props) {
	const { t } = useTranslation();
	const { data } = useMotorInfoQuery();
	const { selectedModelId } = useSelectedMotorModelStore();
	if (!data) return null;
	const { motorInfos } = data;
	const selectedMotor = motorInfos.find(
		(motor) => motor.id === selectedModelId
	);
	if (!selectedMotor)
		return (
			<div className="w-[500px] text-sub-text flex items-center justify-center">
				{t('actuator.select_modal.not_selected_actuator')}
			</div>
		);
	const imgPath = selectedMotor?.model_image;
	const partnerName = selectedMotor?.partner;
	const modelName = selectedMotor?.model_name;

	return (
		<section className="w-[500px] p-5 flex flex-col justify-between">
			<div id="actuator-spec-area" className="flex flex-col gap-y-5">
				<div className="flex gap-x-2.5 items-center">
					{/* process.env.PUBLIC_URL => public/image/sample-actuator.png */}
					<img
						src={imgPath}
						width={136}
						height={69}
						alt={selectedMotor.model_name}
						className="object-contain h-[69px] w-[136px]"
					/>
					<div className="flex flex-col">
						<p className="text-sm text-sub-text">{partnerName}</p>
						<p className="text-lg font-bold">{modelName}</p>
					</div>
				</div>
				<div className="flex flex-col items-start gap-y-3">
					<p className="text-lg font-bold">
						{t('actuator.select_modal.product_specfication')}
					</p>
					<hr className="w-full border-sub-color" />
					<div className="flex flex-col gap-y-[14px] w-full">
						<div className="flex gap-x-5">
							<ForceSpec />
							<div className="h-full border-l border-sub-color" />
							<LengthSpec />
						</div>

						<div className="flex gap-x-5">
							<HallSensorSpec />
							<div className="h-full border-l border-sub-color" />
							<InputVoltageSpec />
						</div>
						<div
							id="apply-button-area"
							className="w-full grid grid-cols-2 gap-x-2.5 h-[47px]"
						>
							<ApplyActuatorButton
								actuatorNumber={1}
								onApply={() => onClose()}
							/>
							<ApplyActuatorButton
								actuatorNumber={2}
								onApply={() => onClose()}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
