import { create } from 'zustand';

interface ForgetStore {
	email: string;
	name: string;
	setEmail: (email: string) => void;
	setName: (name: string) => void;
	init: () => void;
}

const useForgetStore = create<ForgetStore>((set) => ({
	email: '',
	name: '',
	setEmail: (email) => set({ email }),
	setName: (name) => set({ name }),
	init: () => set({ email: '', name: '' }),
}));

export default useForgetStore;
