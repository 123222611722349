import customAxios from 'api';
import axios from 'axios';

type Params = {
	email: string;
	password: string;
};

type Response =
	| {
			accessToken: string;
	  }
	| false;
// 로그인
export const userLogin = async (params: Params) => {
	try {
		const response = await customAxios.get<Response>('/user/userLogIn.php', {
			headers: {
				Authorization: `Basic ${btoa(`${params.email}:${params.password}`)}`,
			},
		});
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unexpected error:', error);
		}
		throw error; // 오류를 호출한 함수로 전달
	}
};
