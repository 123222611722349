import { useTranslation } from 'react-i18next';
import RangeSlider from './RangeSlider';
import { useEffect, useState } from 'react';
import NumberSpinner from './NumberSpinner';
import SaveButton from '../SaveButton';
import { useMotorSetting } from 'hook/useMotorSetting';

export default function SpeedContainer() {
	const { t } = useTranslation();
	const { speed, onSubmitSpeed } = useMotorSetting();
	const [value, setValue] = useState(speed);
	useEffect(() => {
		setValue(speed);
	}, [speed]);

	const disable = speed === value;
	return (
		<form
			className="flex gap-x-5 items-center justify-between px-5 py-1.5"
			onSubmit={(e) => {
				e.preventDefault();
				onSubmitSpeed(value);
			}}
		>
			<div className="flex gap-x-[15px] items-center">
				<span className="font-bold w-[80px]">{t('actuator.speed')}</span>
				<div
					id="speed-slider"
					className="w-[317px] h-[35px] flex items-center gap-x-1"
				>
					<span className="text-sm text-center w-[50px]">0</span>
					<RangeSlider color="red" value={value} setValue={setValue} />
					<span className="text-sm text-center w-[50px]">100</span>
				</div>
				<div className="border-sub-color border px-2.5 py-3 flex items-center justify-between  w-[100px] h-[35px]">
					<span className="text-sm font-bold text-sub-color">value</span>
					<input
						className="text-sm font-bold text-right w-7"
						type="text"
						value={value}
						min={0}
						max={100}
						pattern="[0-9]*"
						onChange={(e) => {
							const newValue = parseInt(e.target.value);
							if (isNaN(newValue)) return setValue(0);
							setValue(newValue);
						}}
						onBlur={() => {
							if (value < 0) setValue(0);
							if (value > 100) setValue(100);
						}}
					/>
				</div>
				<NumberSpinner value={value} setValue={setValue} min={0} max={100} />
			</div>
			<SaveButton id="speed-save-button" type="submit" disabled={disable} />
		</form>
	);
}
