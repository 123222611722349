import clsx from 'clsx';
import useReset from 'hook/useReset';
import { useTranslation } from 'react-i18next';

export default function ResetConfirmButton() {
	const { t } = useTranslation();
	const { active, submitStatus } = useReset();
	return (
		<div className="flex flex-col gap-y-1">
			<button
				type="submit"
				className={clsx(
					'bg-primary-blue text-white rounded px-2.5 py-[14px]',
					'disabled:opacity-50 disabled:cursor-not-allowed'
				)}
				disabled={!active}
			>
				<span className="font-bold text-white">{t('reset.submit_button')}</span>
			</button>
			<p
				className={clsx('text-sm', {
					'text-red-500':
						submitStatus === 'error' || submitStatus === 'not-exist-token',
					'invisible ': submitStatus === 'none',
				})}
			>
				{submitStatus === 'error' && t('reset.fail')}
				{submitStatus === 'not-exist-token' && t('reset.not_exist_token')}
			</p>
		</div>
	);
}
