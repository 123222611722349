import LeftTableHead from './LeftTableHead';
import SpeedTableBody from './SpeedTableBody';

export default function SpeedTable() {
	return (
		<table id="speed-table" className="flex">
			<LeftTableHead />
			<SpeedTableBody />
		</table>
	);
}
