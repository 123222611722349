import { useTranslation } from 'react-i18next';
import OperationButton from './button/OperationButton';
import ResetSettingsButton from './button/ResetSettingsButton';
import { Link } from 'react-router-dom';
import CommunicationButton from './button/CummunicationButton';
import ActuatorSelectButton from './button/ActuatorSelectButton';
import LanguageSelector from './language_selector/LanguageSelector';
import LogoButton from './button/LogoButton';

export default function Header() {
	const { t } = useTranslation();

	return (
		<header
			id="header"
			className="absolute z-40 flex justify-between w-screen p-5 bg-white border-b h-header-height"
		>
			<div id="header-left" className="flex items-center gap-x-10">
				<LogoButton />
				<div className="flex items-center gap-x-[26px]">
					<CommunicationButton />
					<ActuatorSelectButton />
					<Link to={'/profile'} className="font-semibold">
						{t('header.profile')}
					</Link>
				</div>
			</div>
			<div id="header-right" className="flex item-center gap-x-[15px]">
				<LanguageSelector />
				<OperationButton />
				<ResetSettingsButton />
			</div>
		</header>
	);
}
