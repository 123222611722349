export type MotorSetting = {
	id: number;
	user_id: number;
	motor_info_id: number;
	actuator_number: number;
	is_single: number;
	stroke: number;
	detection: number;
	speed: number;
	sensor_setting: number;
	motor_company: number;
	min_stroke: number;
	max_stroke: number;
	hall_count: number;
	switch_mode: number;
	stroke_range: number;
	var_s1_speed: number;
	var_s2_speed: number;
	var_s3_speed: number;
	var_s4_speed: number;
	var_s5_speed: number;
	var_s6_speed: number;
	var_s1_position: number;
	var_s2_position: number;
	var_s3_position: number;
	var_s4_position: number;
	var_s5_position: number;
	var_s6_position: number;
};

export const DEFAULT_MOTOR_SETTING: Omit<
	MotorSetting,
	'id' | 'user_id' | 'actuator_number'
> = {
	motor_info_id: 1,
	is_single: 1,
	stroke: 0,
	detection: 0,
	speed: 0,
	sensor_setting: 0,
	motor_company: 1,
	min_stroke: 0,
	max_stroke: 100,
	hall_count: 0,
	switch_mode: 0,
	stroke_range: 0,
	var_s1_speed: 0,
	var_s2_speed: 0,
	var_s3_speed: 0,
	var_s4_speed: 0,
	var_s5_speed: 0,
	var_s6_speed: 0,
	var_s1_position: 0,
	var_s2_position: 0,
	var_s3_position: 0,
	var_s4_position: 0,
	var_s5_position: 0,
	var_s6_position: 0,
};

export const MAX_ACTUATOR_NUMBER = 2;
