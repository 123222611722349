import { useTranslation } from 'react-i18next';
import useSignupStore from 'store/useSignupStore';
import { inputStyle, labelStyle } from '.';

export default function ContactInformationField() {
	const { t } = useTranslation();
	const { contactInfo, setContactInfo } = useSignupStore();
	return (
		<div id="signup-name-field" className="flex flex-col gap-y-2">
			<label htmlFor="name" className={labelStyle}>
				{t('signup.contact_information_label')}
			</label>
			<input
				className={inputStyle}
				type="text"
				id="name"
				name="name"
				value={contactInfo}
				onChange={(e) => setContactInfo(e.target.value)}
				placeholder={t('signup.contact_information_placeholder')}
				required
			/>
		</div>
	);
}
