import { userDelete } from 'api/auth/userDelete';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {
	disabled: boolean;
};
export default function WithdrawConfirmButton({ disabled }: Props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const onWithdrawConfirm = async () => {
		try {
			await userDelete();
			navigate('/cancel-account/success');
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<button
			id="confirm-cancel-account-button"
			className="bg-warn-red px-2.5 py-[14px] font-bold text-white rounded flex-1 disabled:opacity-50"
			disabled={disabled}
			onClick={onWithdrawConfirm}
		>
			{t('account_cancel.confirm')}
		</button>
	);
}
