import { create } from 'zustand';

interface SignupState {
	email: string;
	password: string;
	confirmPassword: string;
	name: string;
	companyName: string;
	contactInfo: string;
	agreeToPrivacyPolicy: boolean;
	emailCheckedStatus: 'none' | 'loading' | 'success' | 'fail' | 'duplicate';
	passwordMatchStatus: 'none' | 'match' | 'notMatch';
	setEmail: (email: string) => void;
	setPassword: (password: string) => void;
	setConfirmPassword: (confirmPassword: string) => void;
	setName: (name: string) => void;
	setCompanyName: (companyName: string) => void;
	setContactInfo: (contactInfo: string) => void;
	setAgreeToPrivacyPolicy: (agree: boolean) => void;
	setEmailCheckedStatus: (status: SignupState['emailCheckedStatus']) => void;
	setPasswordMatchStatus: (status: SignupState['passwordMatchStatus']) => void;
	reset: () => void;
}

const useSignupStore = create<SignupState>((set) => ({
	email: '',
	password: '',
	confirmPassword: '',
	name: '',
	companyName: '',
	contactInfo: '',
	agreeToPrivacyPolicy: false,
	emailCheckedStatus: 'none',
	passwordMatchStatus: 'none',
	setEmail: (email) => set({ email }),
	setPassword: (password) => set({ password }),
	setConfirmPassword: (confirmPassword) => set({ confirmPassword }),
	setName: (name) => set({ name }),
	setCompanyName: (companyName) => set({ companyName }),
	setContactInfo: (contactInfo) => set({ contactInfo }),
	setAgreeToPrivacyPolicy: (agree) => set({ agreeToPrivacyPolicy: agree }),
	setEmailCheckedStatus: (status) => set({ emailCheckedStatus: status }),
	setPasswordMatchStatus: (status) => set({ passwordMatchStatus: status }),
	reset: () =>
		set({
			email: '',
			password: '',
			confirmPassword: '',
			name: '',
			companyName: '',
			contactInfo: '',
			agreeToPrivacyPolicy: false,
			emailCheckedStatus: 'none',
		}),
}));

export default useSignupStore;
