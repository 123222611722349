import customAxios from 'api';
import { MotorSetting } from '.';

type PartialMotorSetting = Partial<Omit<MotorSetting, 'id' | 'user_id'>> & {
	id: number;
};

export const patchMotorSetting = async (data: PartialMotorSetting) => {
	try {
		const response = await customAxios.patch(
			'/hardware/motorUserSetting.php',
			data
		);
		return response;
	} catch (error) {
		throw error;
	}
};
