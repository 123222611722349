import customAxios from 'api';
import { MotorSetting } from '.';

type Payload = Omit<MotorSetting, 'id' | 'user_id'>;

export const postMotorSetting = async (data: Payload) => {
	try {
		const response = await customAxios.post(
			'/hardware/motorUserSetting.php',
			data
		);
		return response;
	} catch (error) {
		throw error;
	}
};
