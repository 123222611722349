import { useTranslation } from 'react-i18next';
import { graphInputContainerStyle, unitStyle } from '..';
import SaveButton from 'components/actuator/SaveButton';
import { useSpeedSettings } from 'hook/graph/useSpeedSettings';
import { useState } from 'react';
import ConfrimModal from 'components/modal/ConfirmModal';

export default function SpeedGraphRange() {
	const { t } = useTranslation();
	const {
		minSpeedValue,
		maxSpeedValue,
		onChangeMin,
		onChangeMax,
		onSubmit,
		disabled,
	} = useSpeedSettings();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	return (
		<>
			<form
				className="flex gap-x-2.5 items-center"
				onSubmit={(e) => {
					e.preventDefault();
					setIsModalOpen(true);
				}}
			>
				<label className="text-sm font-medium w-[62px]">
					{t('actuator.operation_graph.speed')}
				</label>
				<div className="flex items-center justify-end flex-1 gap-x-4">
					<div id="graph-speed-min" className={graphInputContainerStyle}>
						<span className="text-sm font-medium text-sub-text text-nowrap">
							{t('actuator.operation_graph.min')}
						</span>
						<div className="flex items-center gap-x-1">
							<input
								type="number"
								value={minSpeedValue}
								onChange={onChangeMin}
								className="w-[44px] text-right font-bold"
							/>
							<span className={unitStyle}>mm/s</span>
						</div>
					</div>
					<span className="text-sm">~</span>
					<div id="graph-speed-max" className={graphInputContainerStyle}>
						<span className="text-sm font-medium text-sub-text text-nowrap">
							{t('actuator.operation_graph.max')}
						</span>
						<div className="flex items-center gap-x-1">
							<input
								type="text"
								value={maxSpeedValue}
								onChange={onChangeMax}
								className="w-[44px] text-right font-bold"
							/>
							<span className={unitStyle}>mm/s</span>
						</div>
					</div>
				</div>
				<SaveButton type="submit" disabled={disabled} />
			</form>
			<ConfrimModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onConfirm={onSubmit}
				description={t('actuator.operation_graph.save_speed_description')}
			/>
		</>
	);
}
