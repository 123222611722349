import clsx from 'clsx';
import NavigationPreviousButton from 'components/NavigationPreviousButton';
import usePasswordChange from 'hook/usePasswordChange';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	token: string;
};
export default function PasswordChangeStep2Box({ token }: Props) {
	const { t } = useTranslation();

	const {
		newPassword,
		setNewPassword,
		confirmPassword,
		setConfirmPassword,
		submitStatus,
		onSubmit,
	} = usePasswordChange(token);

	return (
		<section className="p-6 bg-white rounded-[10px] w-[460px] border border-[#EEEEEE]">
			<form className="flex flex-col gap-y-[50px]" onSubmit={onSubmit}>
				<div id="profile-box-header" className="relative flex justify-center">
					<NavigationPreviousButton className="absolute left-0 transform -translate-y-1/2 top-1/2" />
					<span className="text-lg font-bold">{t('password_reset.title')}</span>
				</div>
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-[30px]">
						{/*  새 비밀번호 */}
						<div
							id="change-password-new-password"
							className="flex flex-col gap-y-2"
						>
							<label htmlFor="new-password" className={labelStyle}>
								{t('reset.password_label')}
							</label>
							<input
								id="new-password"
								type="password"
								className="border border-sub-color rounded px-[10px] py-3"
								placeholder={t('reset.password_placeholder')}
								value={newPassword}
								onChange={(e) => setNewPassword(e.target.value)}
							/>
						</div>
						{/* 비밀번호 확인 */}
						<div
							id="change-password-confirm-password"
							className="flex flex-col gap-y-2"
						>
							<label htmlFor="confirm-password" className={labelStyle}>
								{t('reset.password_confirm_label')}
							</label>
							<input
								id="confirm-password"
								type="password"
								className="border border-sub-color rounded px-[10px] py-3"
								placeholder={t('reset.password_confirm_placeholder')}
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-y-1">
						<button
							type="submit"
							className="flex items-center justify-center rounded bg-primary-blue px-2.5 py-[14px]"
						>
							<span className="text-lg font-extrabold text-white">
								{t('reset.submit_button')}
							</span>
						</button>
						{submitStatus === 'error' && (
							<span className="text-red-500">{t('reset.fail')}</span>
						)}
					</div>
				</div>
			</form>
		</section>
	);
}

//style
const labelStyle = clsx('font-bold');
