import clsx from 'clsx';
import { ReactComponent as MoconLogo } from 'asset/icon/mocon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WelcomeText from './WelcomeText';
import { userLogout } from 'api/auth/userLogout';
import AsyncBoundary from 'components/common/AsyncBoundary';
import SimpleLanguageSelector from 'components/header/language_selector/SimpleLanguageSelector';

export default function WelcomeBox() {
	const { t } = useTranslation();
	const onClickLogout = async () => {
		try {
			await userLogout();
		} catch (error) {
			console.error('로그아웃 에러:', error);
		}
	};
	return (
		<div className={boxStyle}>
			<MoconLogo width={182} />
			<div className="flex flex-col">
				<AsyncBoundary SuspenseFallback={<div>Loading...</div>}>
					<WelcomeText />
				</AsyncBoundary>
			</div>
			<div className="flex flex-col gap-y-1">
				{/* logout */}

				<Link
					to="/actuator"
					className="bg-primary-blue text-white rounded px-2.5 py-[14px] w-[400px]"
				>
					<span className="font-bold text-white">{t('welcome.link')}</span>
				</Link>
				<button
					onClick={onClickLogout}
					className="text-sm text-right underline text-sub-text"
				>
					{t('welcome.logout')}
				</button>
			</div>
			<div className="absolute bottom-1 right-1">
				<SimpleLanguageSelector />
			</div>
		</div>
	);
}

//style
const boxStyle = clsx(
	'bg-white border border-[#EEEEEE] rounded-[10px] relative',
	'px-[25px] py-[45px]',
	'flex flex-col gap-y-[30px] items-center'
);
