import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSignup } from 'hook/useSignup';

export default function SignupSubmitButton() {
	const { t } = useTranslation();

	const { submitDisabled } = useSignup();
	return (
		<button className={buttonStyle} disabled={submitDisabled} type="submit">
			{t('signup.submit_button')}
		</button>
	);
}

// buttonStyle
const buttonStyle = clsx(
	'bg-primary-blue rounded py-[14px] px-2.5',
	' text-white font-bold',
	'disabled:opacity-50'
);
