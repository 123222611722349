import { useTranslation } from 'react-i18next';
import { buttonStyle } from '..';
import { ReactComponent as Polygon } from 'asset/icon/polygon.svg';
import { useState } from 'react';
import OperationDropdown from '../operation_dropdown/OperationDropdown';
import { mainText } from 'utils/color';
export default function OperationButton() {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<div className="relative flex">
			<button className={buttonStyle} onClick={() => setIsOpen(!isOpen)}>
				<span>{t('header.operation')}</span>
				<Polygon className="rotate-90" fill={mainText} />
			</button>
			<OperationDropdown isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</div>
	);
}
