import clsx from 'clsx';
import PolicyHead from './PolicyHead';
import PolicyBody from './PolicyBody';

export default function PrivacyPolicyBox() {
	return (
		<div className={boxStyle}>
			<PolicyHead />
			<PolicyBody />
		</div>
	);
}

// style
const boxStyle = clsx(
	'bg-white border border-[#EEEEEE] rounded-[10px]',
	'p-6 w-[460px]',
	'flex flex-col gap-y-[50px]'
);
