import { useProfileQuery } from 'query/useProfileQuery';
import { useTranslation } from 'react-i18next';

export default function WelcomeText() {
	const { t } = useTranslation();
	const { data } = useProfileQuery();

	const userName = data?.name || '';
	return (
		<p className="text-xl text-left">
			{t('welcome.description', { name: userName })}
		</p>
	);
}
