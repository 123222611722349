import { ReactComponent as Logo } from 'asset/logo/header-logo.svg';
import { Link } from 'react-router-dom';
import useAuthStore from 'store/useAuthStore';

export default function LogoButton() {
	const { accessToken } = useAuthStore();

	const isAuth = accessToken !== null;

	const path = isAuth ? '/actuator' : '/';
	return (
		<Link to={path}>
			<Logo />
		</Link>
	);
}
