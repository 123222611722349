import { useTranslation } from 'react-i18next';

export default function PolicyBody() {
	const { t } = useTranslation();
	return (
		<section className="flex flex-col gap-y-7">
			<h2 className="text-2xl font-bold">{t('policy.sub_title')}</h2>
			<div id="policy-sector1">
				<h3 className="text-lg font-bold ">{t('policy.sector1.title')}</h3>
				<h4 className="mt-2 text-base font-bold">
					{t('policy.sector1.sub_title1')}
				</h4>
				<p className="text-sm">{t('policy.sector1.content1')}</p>
				<p className="text-sm">{t('policy.sector1.content2')}</p>
				<p className="text-sm">{t('policy.sector1.content3')}</p>
				<p className="text-sm">{t('policy.sector1.content4')}</p>
				<p className="text-sm">{t('policy.sector1.content5')}</p>
				<p className="text-sm">{t('policy.sector1.content6')}</p>
				<h4 className="mt-2 text-base font-bold">
					{t('policy.sector1.sub_title2')}
				</h4>
				<p className="text-sm">{t('policy.sector1.content7')}</p>
				<p className="text-sm">{t('policy.sector1.content8')}</p>
			</div>
			<div id="policy-sector2">
				<h3 className="text-lg font-bold">{t('policy.sector2.title')}</h3>
				<p className="text-sm">{t('policy.sector2.content1')}</p>
				<p className="text-sm">{t('policy.sector2.content2')}</p>
				<p className="text-sm">{t('policy.sector2.content3')}</p>
			</div>
			<div id="policy-sector3">
				<h3 className="text-lg font-bold">{t('policy.sector3.title')}</h3>
				<p className="text-sm">{t('policy.sector3.content1')}</p>
				<p className="text-sm">{t('policy.sector3.content2')}</p>
				<p className="text-sm">{t('policy.sector3.content3')}</p>
				<p className="text-sm">{t('policy.sector3.content4')}</p>
				<p className="text-sm">{t('policy.sector3.content5')}</p>
				<p className="text-sm">{t('policy.sector3.content6')}</p>
				<p className="text-sm">{t('policy.sector3.content7')}</p>
				<p className="text-sm">{t('policy.sector3.content8')}</p>
			</div>
			<div id="policy-sector4">
				<h3 className="text-lg font-bold">{t('policy.sector4.title')}</h3>
				<p className="text-sm">{t('policy.sector4.content1')}</p>
				<p className="text-sm">{t('policy.sector4.content2')}</p>
				<p className="text-sm">{t('policy.sector4.content3')}</p>
				<p className="text-sm">{t('policy.sector4.content4')}</p>
				<p className="text-sm">{t('policy.sector4.content5')}</p>
				<p className="text-sm">{t('policy.sector4.content6')}</p>
				<p className="text-sm">{t('policy.sector4.content7')}</p>
				<p className="text-sm">{t('policy.sector4.content8')}</p>
				<p className="text-sm">{t('policy.sector4.content9')}</p>
				<p className="text-sm">{t('policy.sector4.content10')}</p>
				<p className="text-sm">{t('policy.sector4.content11')}</p>
			</div>
		</section>
	);
}
