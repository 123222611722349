import { ReactComponent as BracketUp } from 'asset/icon/bracket-up.svg';
import { primaryBlue } from 'utils/color';
type Props = {
	value: number;
	setValue: (value: number) => void;
	min?: number;
	max?: number;
};

export default function NumberSpinner({ value, setValue, min, max }: Props) {
	return (
		<div className="flex h-10 border rounded-full border-sub-color">
			<button
				type="button"
				className="px-3 flexCenter"
				onClick={() => {
					if (typeof min === 'number' && value <= min) return;
					else setValue(value - 1);
				}}
				// 꾹 누르고 있기
			>
				<BracketUp className="rotate-180" stroke={primaryBlue} />
			</button>
			<button
				type="button"
				className="px-3 flexCenter"
				onClick={() => {
					if (typeof max === 'number' && value >= max) return;
					else setValue(value + 1);
				}}
			>
				<BracketUp stroke={primaryBlue} />
			</button>
		</div>
	);
}
