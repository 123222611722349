import NavigationPreviousButton from 'components/NavigationPreviousButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ProfileEditButton from './ProfileEditButton';
import LogoutButton from './LogoutButton';
import ProfileText from './ProfileText';
import AsyncBoundary from 'components/common/AsyncBoundary';
export default function ProfileBox() {
	const { t } = useTranslation();

	return (
		<section className="p-6 bg-white rounded-[10px] w-[460px] border border-[#EEEEEE]">
			<div className="flex flex-col gap-y-[50px]">
				<div id="profile-box-header" className="relative flex justify-center">
					<NavigationPreviousButton className="absolute left-0 transform -translate-y-1/2 top-1/2" />
					<span className="text-lg font-bold">{t('profile.profile')}</span>
				</div>
				<div id="profile-box-content" className="flex flex-col gap-y-4">
					<div id="profile-email" className="flex items-center justify-between">
						<label className={labelStyle}>{t('profile.email')}</label>
						<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
							<ProfileText type="email" />
						</AsyncBoundary>
					</div>
					<div id="profile-name" className="flex items-center justify-between">
						<label className={labelStyle}>{t('profile.name')}</label>
						<div className="flex items-center gap-x-2.5">
							<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
								<ProfileText type="name" />
							</AsyncBoundary>{' '}
							<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
								<ProfileEditButton type="name" />
							</AsyncBoundary>
						</div>
					</div>
					<div
						id="profile-company-name"
						className="flex items-center justify-between"
					>
						<label className={labelStyle}>{t('profile.company_name')}</label>
						<div className="flex items-center gap-x-2.5">
							<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
								<ProfileText type="companyName" />
							</AsyncBoundary>
							<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
								<ProfileEditButton type="companyName" />
							</AsyncBoundary>
						</div>
					</div>
					<div
						id="profile-phone-number"
						className="flex items-center justify-between"
					>
						<label className={labelStyle}>
							{t('profile.contact_information')}
						</label>
						<div className="flex items-center gap-x-2.5">
							<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
								<ProfileText type="contact" />
							</AsyncBoundary>
							<AsyncBoundary SuspenseFallback={<span>Loading...</span>}>
								<ProfileEditButton type="contact" />
							</AsyncBoundary>
						</div>
					</div>
					{/* password reset */}
					<Link
						to="/password-reset"
						className="bg-sub-color px-2.5 py-[14px] font-bold text-center flex items-center justify-center rounded"
					>
						{t('profile.password_reset')}
					</Link>
					{/* logout button */}
					<LogoutButton />
					{/* cancel account */}
					<Link
						to="/cancel-account"
						className="underline text-[#676767] text-sm text-center"
					>
						{t('profile.cancel_account')}
					</Link>
				</div>
			</div>
		</section>
	);
}
// style
const labelStyle = 'font-bold text-left';
