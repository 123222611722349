import clsx from 'clsx';

type Props = {
	checked: boolean;
};

export default function FilledCheckBox({ checked }: Props) {
	return (
		<div className={clsx(getCheckContainer(checked), {})}>
			{checked && (
				<svg
					width="10"
					height="9"
					viewBox="0 0 10 9"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1 3.22222L4.42857 7L9 1"
						stroke="white"
						stroke-width="2"
						stroke-linecap="round"
					/>
				</svg>
			)}
		</div>
	);
}

//style
const getCheckContainer = (checked: boolean) =>
	clsx(
		'flex items-center justify-center min-w-[14px] w-[14px] h-[14px]  rounded-sm',
		{
			'border border-sub-color': !checked,
			'bg-primary-blue': checked,
		}
	);
