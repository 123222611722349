import customAxios from 'api';

export type GraphSetting = {
	id: number;
	max_stroke: number;
	min_stroke: number;
	max_detection: number;
	min_detection: number;
	max_speed: number;
	min_speed: number;
};

type Response = GraphSetting[];

// 그래프 설정 정보 가져오기
export const getGraphSetting = async () => {
	try {
		const response = await customAxios.get<Response>(
			'/hardware/graphSetting.php'
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};
