import Select from 'react-select';
import { selectStyle } from '.';
import { useTranslation } from 'react-i18next';
import { OptionType } from 'type/select';

export default function LanguageSelector() {
	const { i18n } = useTranslation();
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng); // 언어 전환
	};
	const languageOptions: OptionType[] = [
		{ value: 'en', label: 'English' },
		{ value: 'ko', label: '한국어' },
	];
	return (
		<Select
			styles={selectStyle}
			isSearchable={false}
			value={languageOptions.find((option) => option.value === i18n.language)}
			options={languageOptions}
			onChange={(selectedOption) => {
				if (selectedOption) {
					localStorage.setItem('language', selectedOption.value);
					changeLanguage(selectedOption.value);
				}
			}}
			components={{
				IndicatorSeparator: () => null,
			}}
		/>
	);
}
