import Modal from 'react-modal';
import { customModalStyles } from 'components/modal/style';
import { useTranslation } from 'react-i18next';
import useCancelAgreementStore from 'store/useCancleAgreementStore';

export default function CancelAgreementModal() {
	const { t } = useTranslation();
	const { isModalOpen, setModalOpen, setAgreement } = useCancelAgreementStore();

	return (
		<Modal
			isOpen={isModalOpen}
			style={customModalStyles}
			onRequestClose={() => setModalOpen(false)}
			className="max-w-2xl"
		>
			<div className="w-full max-w-2xl p-6 bg-white border rounded-lg border-sub-color">
				<h1 className="mb-4 text-2xl font-bold text-center text-primary-blue">
					{t('account_cancel.agreement.title')}
				</h1>
				<p className="mb-6 text-sm leading-relaxed text-gray-700">
					{t('account_cancel.agreement.subtitle')}
				</p>
				<div className="space-y-4 text-sm text-gray-600">
					<div className="flex items-start gap-x-2">
						<span className="font-bold text-primary-blue">1.</span>
						<p>{t('account_cancel.agreement.item_1')}</p>
					</div>
					<div className="flex items-start gap-x-2">
						<span className="font-bold text-primary-blue">2.</span>
						<p>{t('account_cancel.agreement.item_2')}</p>
					</div>
					<div className="flex items-start gap-x-2">
						<span className="font-bold text-primary-blue">※</span>
						<p>{t('account_cancel.agreement.note')}</p>
					</div>
				</div>
				<p className="mt-6 text-sm text-center text-gray-700">
					{t('account_cancel.description')}
				</p>
				<button
					onClick={() => {
						setAgreement(true);
						setModalOpen(false);
					}}
					className="w-full py-3 mt-4 text-white rounded-lg bg-primary-blue"
				>
					{t('account_cancel.agreement.confirm_button')}
				</button>
			</div>
		</Modal>
	);
}
