//구간 개수에 따른 초기 속도값을 반환하는 함수
export const getInitialsSpeed = (rangeNumber: number): number[] => {
	const half = Math.floor(rangeNumber / 2) + 1;
	// 0~100 사이에서, 0부터 올라갔다가 다시 0으로 내려가는 속도값 배열을 반환
	const speedArray = Array.from({ length: rangeNumber + 1 }, (_, index) =>
		index <= rangeNumber / 2
			? Math.floor((index / half) * 100)
			: Math.floor(((rangeNumber - index) / half) * 100)
	);

	return speedArray;
};

//구간 개수에 따른 초기 포지션값을 반환하는 함수
export const getInitialsPosition = (rangeNumber: number): number[] => {
	const positionArray = Array.from({ length: rangeNumber + 1 }, (_, index) =>
		Math.floor((index / rangeNumber) * 100)
	);
	return positionArray;
};
