import {
	useGraphSettingsMutation,
	useGraphSettingsQuery,
} from 'query/useGraphSettingsQuery';
import { useState } from 'react';

export const useStrokeSettings = () => {
	const { data: graphSetting } = useGraphSettingsQuery();
	const { mutateAsync: setGraphSettings } = useGraphSettingsMutation();

	const id = graphSetting?.id ?? 0;
	const minStroke = graphSetting?.min_stroke ?? 0;
	const maxStroke = graphSetting?.max_stroke ?? 100;

	const [minStrokeValue, setMinStrokeValue] = useState<string>(`${minStroke}`);
	const [maxStrokeValue, setMaxStrokeValue] = useState<string>(`${maxStroke}`);

	const onChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) < 0) {
			e.target.value = `${minStrokeValue}`;
			return;
		}
		setMinStrokeValue(value);
	};

	const onChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) < 0) {
			e.target.value = `${maxStrokeValue}`;
			return;
		}
		setMaxStrokeValue(value);
	};

	const onSubmit = () => {
		try {
			if (Number(minStrokeValue) < Number(maxStrokeValue)) {
				setGraphSettings({
					id,
					min_stroke: Number(minStrokeValue),
					max_stroke: Number(maxStrokeValue),
				});
			} else {
				setGraphSettings({
					id,
					min_stroke: Number(maxStrokeValue),
					max_stroke: Number(minStrokeValue),
				});
			}
		} catch (error) {
			console.error(error);
		}
	};

	const disabled =
		(minStrokeValue === `${minStroke}` && maxStrokeValue === `${maxStroke}`) ||
		minStrokeValue === '' ||
		maxStrokeValue === '' ||
		Number(minStrokeValue) < 0 ||
		Number(maxStrokeValue) < 0 ||
		Number(minStrokeValue) > Number(maxStrokeValue);

	return {
		id,
		minStroke,
		maxStroke,
		minStrokeValue,
		maxStrokeValue,
		onChangeMin,
		onChangeMax,
		onSubmit,
		disabled,
	};
};
