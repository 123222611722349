import { ReactComponent as SaveIcon } from 'asset/icon/save.svg';
import { useTranslation } from 'react-i18next';

type Props = {
	id?: string;
	onClick?: () => void;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
};
export default function SaveButton({ id, onClick, type, disabled }: Props) {
	const { t } = useTranslation();
	return (
		<button
			id={id}
			onClick={onClick}
			type={type}
			className="px-[14px] py-3 bg-primary-blue rounded flex gap-x-[5px] items-center disabled:opacity-30"
			disabled={disabled}
		>
			<SaveIcon fill="white" />
			<span className="text-sm font-bold text-white">{t('actuator.save')}</span>
		</button>
	);
}
