import { useMotorSettingsQuery } from 'query/useMotorSettingsQuery';
import ActuatorItem from './ActuatorItem';
import ActuatorPlus from './ActuatorPlus';
import useSelectedActuatorStore from 'store/useSelectedActuatorStore';
import { useEffect } from 'react';

export default function ActuatorSelector() {
	const { data: motorSettings } = useMotorSettingsQuery();
	const { setSelectedActuatorNumber } = useSelectedActuatorStore();
	useEffect(() => {
		if (motorSettings) {
			const selectedActuatorNumber = motorSettings[0]?.actuator_number;
			setSelectedActuatorNumber(selectedActuatorNumber);
		}
	}, [motorSettings, setSelectedActuatorNumber]);
	if (!motorSettings) return null;

	return (
		<div className="flex gap-x-[14px] items-center">
			{motorSettings.map((motorSetting, index) => (
				<ActuatorItem
					key={motorSetting.actuator_number}
					actuatorNumber={motorSetting.actuator_number}
					index={index}
				/>
			))}
			<ActuatorPlus />
		</div>
	);
}
