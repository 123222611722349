import Modal from 'react-modal';
import { customModalStyles } from '../style';
import { useTranslation } from 'react-i18next';
import CloseCrossButton from '../CloseCrossButton';
import SelectedActuatorArea from './SelectedActuatorArea';
import ActuatorModelSidebar from './sidebar/ActuatorModelSidebar';
import { Suspense, useState } from 'react';
import PartnersSidebar from './sidebar/PartnersSidebar';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

export default function SelectActuatorModal({ isOpen, onClose }: Props) {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={isOpen}
			style={customModalStyles}
			onRequestClose={onClose}
			className="p-0 w-[840px] bg-white"
		>
			<header className="flex justify-between p-[18px]">
				<span className="text-lg font-bold">
					{t('actuator.select_modal.title')}
				</span>
				<CloseCrossButton onClick={onClose} />
			</header>
			<div
				id="actuator-select-modal-content"
				className="border-t border-sub-color flex divide-x h-[420px]"
			>
				<Suspense fallback={<div>Loading...</div>}>
					<PartnersSidebar />
				</Suspense>
				<Suspense fallback={<div>Loading...</div>}>
					<ActuatorModelSidebar />
				</Suspense>
				<Suspense fallback={<div>Loading...</div>}>
					<SelectedActuatorArea onClose={onClose} />
				</Suspense>
			</div>
		</Modal>
	);
}
