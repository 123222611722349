import clsx from 'clsx';
import { useForget } from 'hook/useForget';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useForgetStore from 'store/useForgetStore';

export default function PasswordForgotBox() {
	const { t } = useTranslation();
	const { email, name, setEmail, setName } = useForgetStore();
	const { isValidateEmail, onSubmit, submitStatus } = useForget();
	const [isEmailFieldFocused, setIsEmailFieldFocused] =
		useState<boolean>(false); // 이메일 input이 focus 되었는지 여부

	const showEmailError = !isValidateEmail(email) && isEmailFieldFocused;
	const isButtonDisabled = !isValidateEmail(email) || name === '';

	return (
		<div className={boxStyle}>
			<form className={'flex flex-col gap-y-[30px]'} onSubmit={onSubmit}>
				<h1 className="text-2xl font-bold text-center">{t('forget.title')}</h1>
				<div className="flex gap-y-[25px] flex-col">
					<div className="flex flex-col gap-y-2 ">
						<label htmlFor="email" className={labelStyle}>
							{t('forget.email_label')}
						</label>
						<div className="relative">
							<input
								id="email"
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								onFocus={() => setIsEmailFieldFocused(true)}
								className="w-full border border-sub-color rounded px-[10px] py-3"
								placeholder={t('forget.email_placeholder')}
							/>
							<span
								id="email-error"
								className={clsx(
									'text-sm text-red-500 absolute bottom-[-20px] left-0 ',
									{
										hidden: !showEmailError,
									}
								)}
							>
								{t('forget.worng_email_format')}
							</span>
						</div>
					</div>
					<div className="flex flex-col gap-y-2">
						<label htmlFor="name" className={labelStyle}>
							{t('forget.name_label')}
						</label>
						<input
							id="name"
							type="text"
							value={name}
							onChange={(e) => setName(e.target.value)}
							className="border border-sub-color rounded px-[10px] py-3"
							placeholder={t('forget.name_placeholder')}
						/>
					</div>
				</div>
				<div className="relative" id="submit-button-container">
					<button
						type="submit"
						className={submitButtonStyle}
						disabled={isButtonDisabled}
					>
						<span className="text-lg font-extrabold text-white">
							{t('forget.next')}
						</span>
					</button>
					{submitStatus === 'success' && (
						<p className="text-sm text-green-500 absolute bottom-[-20px] left-0">
							{t('forget.success')}
						</p>
					)}
					{submitStatus === 'fail' && (
						<p className="text-sm text-red-500 absolute bottom-[-20px] left-0">
							{t('forget.fail')}
						</p>
					)}
					{submitStatus === 'unexist' && (
						<p className="text-sm text-red-500 absolute bottom-[-20px] left-0">
							{t('forget.not_exist')}
						</p>
					)}
				</div>
			</form>
		</div>
	);
}
//style
const boxStyle = clsx(
	'bg-white border border-[#EEEEEE] rounded-[10px]',
	'px-[25px] py-[45px] w-[460px]'
);

const labelStyle = 'font-bold';
const submitButtonStyle = clsx(
	'w-full flex items-center justify-center rounded bg-primary-blue px-2.5 py-[14px]',
	'disabled:opacity-30'
);
