//access token이 정상적으로 서버로 전송되는지 확인하는 api

import customAxios from 'api';
import axios from 'axios';

type Response = {
	success: boolean;
	message: string;
	accessToken?: string;
};

export const checkAccess = async () => {
	try {
		const response = await customAxios.get<Response>('/test/checkAccess.php');

		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unexpected error:', error);
		}
		throw error; // 오류를 호출한 함수로 전달
	}
};
