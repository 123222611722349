import { DEFAULT_MOTOR_SETTING } from 'api/motor_setting';
import {
	useAddMotorMutation,
	useMotorSettingsQuery,
} from 'query/useMotorSettingsQuery';

export default function ActuatorPlus() {
	const { data: motorSettings } = useMotorSettingsQuery();
	const { mutateAsync: addMotor } = useAddMotorMutation();
	if (!motorSettings) return null;

	// MotorSetting | undefined [] 에서 새로 부여될 actuator_number를 구함
	const newActuatorNumber = motorSettings[0].actuator_number === 1 ? 2 : 1;

	const onClick = async () => {
		await addMotor({
			...DEFAULT_MOTOR_SETTING,
			actuator_number: newActuatorNumber,
		});
	};

	if (motorSettings[1]) return null;
	return (
		<button
			onClick={onClick}
			className="h-[45px] w-[45px] rounded-md bg-white border-sub-color border flex items-center justify-center"
		>
			<span className="text-[14px] font-semibold text-sub-text ">+</span>
		</button>
	);
}
