import clsx from 'clsx';
import useActuatorValueStore from 'store/useActuatorValueStore';

export default function StrokePercent() {
	const { positionArray } = useActuatorValueStore();
	//전체 스트로크 길이
	return (
		<div className={containerStyle}>
			{positionArray.map((position, index) => {
				if (index === 0) return null;

				return (
					<div
						key={index}
						className={positionStyle}
						style={{
							width:
								index === positionArray.length - 1
									? undefined
									: `${position - positionArray[index - 1]}%`,
							flex: index === positionArray.length - 1 ? 1 : undefined,
						}}
					>
						<span className="text-sm font-bold text-primary-blue ">{`S${index}`}</span>
					</div>
				);
			})}
		</div>
	);
}

const containerStyle = clsx(
	'flex border divide-x border-sub-color divide-sub-color w-[660px]'
);
const positionStyle = clsx(
	'bg-selected-gray py-1.5 px-1.5 flex items-center justify-center'
);
