import { useTranslation } from 'react-i18next';
import SpeedModeToggle from './speed_table/SpeedModeToggle';

export default function SpeedControlHeader() {
	const { t } = useTranslation();
	return (
		<header className="flex items-center justify-between">
			<h3 className="text-sm font-bold">{t('actuator.speed_control.title')}</h3>
			<SpeedModeToggle />
		</header>
	);
}
