import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function CurrentStateArea() {
	const { t } = useTranslation();
	return (
		<div className="flex px-5 py-[14px] gap-x-[14px] h-[78px]">
			<div className="bg-sub-color px-5 py-1.5 rounded-lg flex items-center gap-x-[14px] flex-1">
				<div id="current-state-voltage" className="flex items-center gap-x-2">
					<span className={labelStyle}>
						{t('actuator.current_state.voltage')}
					</span>
					<div className="flex items-center gap-x-[5px]">
						<span className={valueStyle}>160</span>
						<span className={unitStyle}>V</span>
					</div>
				</div>
				<Divider />
				{/* Strokes  */}
				<div id="current-state-stroke" className="flex items-center gap-x-2">
					<span className={labelStyle}>
						{t('actuator.current_state.strokes')}
					</span>
					<div className="flex items-center gap-x-[5px]">
						<span className={valueStyle}>160</span>
						<span className={unitStyle}>mm</span>
					</div>
				</div>
				{/* Temperature */}
				<Divider />
				<div
					id="current-state-temperature"
					className="flex items-center gap-x-2"
				>
					<span className={labelStyle}>
						{t('actuator.current_state.temperature')}
					</span>
					<div className="flex items-center gap-x-[5px]">
						<span className={valueStyle}>160</span>
						<span className={unitStyle}>°C</span>
					</div>
				</div>
			</div>
		</div>
	);
}
// style
const labelStyle = clsx('text-sm font-bold');
const valueStyle = clsx('text-sm font-medium text-sub-text');
const unitStyle = clsx('text-sm font-bold text-sub-text');

const Divider = () => (
	<svg
		width="1"
		height="16"
		viewBox="0 0 1 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M0.5 0.5V15.5" stroke="#9A9A9A" />
	</svg>
);
