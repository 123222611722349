import clsx from 'clsx';
import AsyncBoundary from 'components/common/AsyncBoundary';
import NavigationPreviousButton from 'components/NavigationPreviousButton';
import { useTranslation } from 'react-i18next';
import EmailText from './EmailText';
import { verifyPassword } from 'api/profile/verifyPassword';
import { useState } from 'react';

type Props = {
	setToken: (token: string) => void;
};
export default function PasswordChangeStep1Box({ setToken }: Props) {
	const { t } = useTranslation();

	const [status, setStatus] = useState<
		'loading' | 'error' | 'success' | 'none' | 'fail'
	>('none');
	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		//id == current-password 인 input의 value를 가져오기
		const currentPassword = (
			e.currentTarget.querySelector('#current-password') as HTMLInputElement
		).value;
		try {
			setStatus('loading');
			const response = await verifyPassword(currentPassword);
			setStatus('success');
			setToken(response.password_reset_token);
		} catch (error) {
			console.error(error);
			setStatus('fail');
		}
	};
	//password_reset.status.
	return (
		<section className="p-6 bg-white rounded-[10px] w-[460px] border border-[#EEEEEE]">
			<form className="flex flex-col gap-y-[50px]" onSubmit={handleSubmit}>
				<div id="profile-box-header" className="relative flex justify-center">
					<NavigationPreviousButton className="absolute left-0 transform -translate-y-1/2 top-1/2" />
					<span className="text-lg font-bold">{t('password_reset.title')}</span>
				</div>
				<div className="flex flex-col gap-y-4">
					<div className="flex flex-col gap-y-[30px]">
						<div
							id="change-password-email"
							className="flex items-center justify-between"
						>
							<label className={labelStyle}>
								{t('password_reset.email_label')}
							</label>
							<AsyncBoundary SuspenseFallback={<div>Loading...</div>}>
								<EmailText />
							</AsyncBoundary>
						</div>
						<div
							id="change-password-current-password"
							className="flex flex-col gap-y-2"
						>
							<label htmlFor="current-password" className={labelStyle}>
								{t('password_reset.current_password_label')}
							</label>
							<input
								id="current-password"
								type="password"
								className="border border-sub-color rounded px-[10px] py-3"
								placeholder={t('password_reset.current_password_placeholder')}
							/>
							{status === 'fail' && (
								<span className="text-red-500">
									{t('password_reset.status.fail')}
								</span>
							)}
						</div>
					</div>
					<button
						type="submit"
						className="flex items-center justify-center rounded bg-primary-blue px-2.5 py-[14px]"
					>
						<span className="text-lg font-extrabold text-white">
							{t('password_reset.next')}
						</span>
					</button>
				</div>
			</form>
		</section>
	);
}

//style
const labelStyle = clsx('font-bold');
