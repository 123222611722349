import ReactModal from 'react-modal';
import { customModalStyles } from './style';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
	title?: string;
	isOpen: boolean;
	onClose: () => void;
	onSubmit?: (value: string) => void;
	onCancel?: () => void;
	cancelText?: string;
	confirmText?: string;
	cancel?: boolean;
	label?: string;
	placeholder?: string;
	defaultValue?: string;
};

export default function TextInputModal(props: Props) {
	const { t } = useTranslation();
	const {
		isOpen,
		onClose,
		title,
		onSubmit,
		cancel = true,
		cancelText,
		confirmText,
		onCancel,
		label,
		placeholder,
		defaultValue,
	} = props;
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const input = (e.target as HTMLFormElement).querySelector(
			'#input'
		) as HTMLInputElement;

		onSubmit && onSubmit(input.value);
		onClose();
	};
	const handleCancel = () => {
		onCancel && onCancel();
		onClose();
	};
	return (
		<ReactModal
			isOpen={isOpen}
			style={customModalStyles}
			onRequestClose={onClose}
			className={clsx('p-6 w-[448px]')}
		>
			<form
				onSubmit={handleSubmit}
				className="flex flex-col h-full gap-y-[30px]"
			>
				<div className="flex flex-col gap-y-[30px]  text-center pt-5">
					<p className="text-[22px] font-bold">
						{title || t('confirm.change_information')}
					</p>
					<div className="flex flex-col items-start gap-y-2">
						{label && (
							<label htmlFor="input" className="font-bold">
								{label}
							</label>
						)}
						<input
							id="input"
							type="text"
							placeholder={placeholder}
							defaultValue={defaultValue}
							className="border border-[#E0E0E0] rounded-[5px] h-[43px] px-2.5 w-full"
						/>
					</div>
				</div>
				<div id="confirm-modal-buttons" className="h-[47px] flex gap-x-[15px]">
					{cancel && (
						<button
							id="cancel-button"
							onClick={handleCancel}
							className="flex items-center justify-center border rounded border-primary-blue px-2.5"
						>
							<span className="font-extrabold text-primary-blue">
								{cancelText || t('confirm.cancel')}
							</span>
						</button>
					)}
					<button
						id="confirm-button"
						type="submit"
						className="flex items-center justify-center flex-1 rounded bg-primary-blue"
					>
						<span className="font-extrabold text-white">
							{confirmText || t('confirm.confirm')}
						</span>
					</button>
				</div>
			</form>
		</ReactModal>
	);
}
