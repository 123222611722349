import CheckBox from 'components/common/CheckBox';
import ModalToReviewButton from '../ModalToReviewButton';
import { useTranslation } from 'react-i18next';
import useCancelAgreementStore from 'store/useCancleAgreementStore';

export default function CancelAgreement() {
	const { t } = useTranslation();
	const { isAgreed, setAgreement } = useCancelAgreementStore();

	return (
		<div className="bg-[#EEECF3] rounded px-[14px] py-5 flex justify-between items-center">
			<div className="flex items-center gap-x-2.5">
				<button className="bg-white" onClick={() => setAgreement(!isAgreed)}>
					<CheckBox checked={isAgreed} />
				</button>
				<p className="text-sm">{t('account_cancel.confirm_check_message')}</p>
			</div>
			<ModalToReviewButton />
		</div>
	);
}
