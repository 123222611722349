import customAxios from 'api';
import { MotorSetting } from '.';

export const getMotorSetting = async (actuatorNumber?: number) => {
	try {
		const response = await customAxios.get<MotorSetting[]>(
			`/hardware/motorUserSetting.php${
				actuatorNumber ? `?actuator_number=${actuatorNumber}` : ''
			}`
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};
