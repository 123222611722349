import AgreePolicyField from './AgreePolicyField';
import CompanyNameField from './CompanyNameField';
import ContactInformationField from './ContactInformationField';
import EmailField from './EmailField';
import NameField from './NameField';
import PasswordConfirmField from './PasswordConfirmField';
import PasswordField from './PasswordField';
import SignupSubmitButton from './SignupSubmitButton';

export default function FormBody() {
	return (
		<div className="flex flex-col gap-y-[30px]">
			<EmailField />
			<PasswordField />
			<PasswordConfirmField />
			<NameField />
			<CompanyNameField />
			<ContactInformationField />
			<AgreePolicyField />
			<SignupSubmitButton />
		</div>
	);
}
