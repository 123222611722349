import clsx from 'clsx';
import ReactModal from 'react-modal';

/*overlay는 모달 창 바깥 부분, content는 모달 창부분*/
export const customModalStyles: ReactModal.Styles = {
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		width: '100%',
		height: '100vh',
		zIndex: '40',
		position: 'fixed',
		top: '0',
		left: '0',
	},
	content: {
		zIndex: '150',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: '10px',
		boxShadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
		backgroundColor: 'white',
		justifyContent: 'center',
		overflow: 'auto',
	},
};

//button
export const cancelStyle = clsx(
	'flex items-center justify-center border rounded border-primary-blue px-2.5 py-[14px]',
	'font-extrabold text-primary-blue ',
	'w-[95px]'
);

export const confirmStyle = clsx(
	'flex items-center justify-center gap-x-2.5 flex-1 rounded bg-primary-blue px-2.5 py-[14px]',
	'font-extrabold text-white',
	'flex-1'
);
