import customAxios from 'api';
import axios from 'axios';

type Params = {
	email: string;
	name: string;
};

export const userResetPassword = async (param: Params) => {
	try {
		const response = await customAxios.post('/user/userResetPassword.php', {
			email: param.email,
			name: param.name,
		});
		return response.data;
	} catch (error) {
		//401 error
		if (axios.isAxiosError(error) && error.response?.status === 401) {
			return 'unexist';
		}
		throw new Error('error');
	}
};
