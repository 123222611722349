import { create } from 'zustand';

interface ResetStore {
	password: string;
	passwordConfirmation: string;
	submitStatus: 'success' | 'error' | 'loading' | 'none' | 'not-exist-token';
	setPassword: (password: string) => void;
	setPasswordConfirmation: (passwordConfirmation: string) => void;
	setSubmitStatus: (submitStatus: ResetStore['submitStatus']) => void;
	reset: () => void;
}

const useResetStore = create<ResetStore>((set) => ({
	password: '',
	passwordConfirmation: '',
	submitStatus: 'none',
	setPassword: (password) => set({ password }),
	setPasswordConfirmation: (passwordConfirmation) =>
		set({ passwordConfirmation }),
	reset: () => set({ password: '', passwordConfirmation: '' }),
	setSubmitStatus: (submitStatus) => set({ submitStatus }),
}));

export default useResetStore;
