import AsyncBoundary from 'components/common/AsyncBoundary';
import { boxContainer } from '.';
import ActuatorHead from './ActuatorHead';
import CurrentStateArea from './current_state/CurrentStateArea';
import ActuatorGraphBox from './graph/ActuatorGraphBox';
import GraphSettingArea from './graph/graph_setting/GraphSettingArea';
import SensorSettingArea from './sensor_setting/SensorSettingButtons';
import SpeedControlArea from './speed_control/SpeedControlArea';
import SwitchModeArea from './switch_mode/SwitchModeArea';

import { useTranslation } from 'react-i18next';
import ValueControlArea from './value_control/ValueControlArea';

export default function ActuatorBox() {
	const { t } = useTranslation();

	return (
		<div className={boxContainer}>
			<ActuatorHead />
			<div
				id="actuator-contents"
				className="flex min-[1341px]:divide-x max-[1340px]:flex-col  max-[1340px]:divide-y"
			>
				<div className="flex flex-col w-[770px] divide-y flex-1">
					<div className="flex items-center py-[14px] px-5 gap-x-5 h-[78px] ">
						<span className="text-sm font-bold w-[61px]">
							{t('actuator.check_type.title')}
						</span>
						<AsyncBoundary
							SuspenseFallback={<div className="w-[542px] h-[50px]" />}
						>
							<SensorSettingArea />
						</AsyncBoundary>
					</div>
					<AsyncBoundary
						SuspenseFallback={<div className="w-[770px] h-[199px]" />}
					>
						<ValueControlArea />
					</AsyncBoundary>
					<SpeedControlArea />
				</div>
				<div className="flex flex-col divide-y flex-1 min-[1341px]:max-w-[540px]">
					<CurrentStateArea />
					<SwitchModeArea />
					<ActuatorGraphBox />
					<GraphSettingArea />
				</div>
			</div>
		</div>
	);
}
