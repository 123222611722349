import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

export default function LeftTableHead() {
	const { t } = useTranslation();
	return (
		<div className="w-[140px] flex flex-col divide-y divide-white">
			<th className={itemStyle}>{t('actuator.speed_control.range')}</th>

			<th className={itemStyle}>{t('actuator.speed_control.stroke')}(mm)</th>
			<th className={itemStyle}>{t('actuator.speed_control.position')}(%)</th>
			<th className={itemStyle}>{t('actuator.speed_control.speed')}(%)</th>
		</div>
	);
}

const itemStyle = clsx(
	'w-[140px] p-[14px] py-2 text-sm bg-sub-color font-normal text-left'
);
