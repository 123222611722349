import NavigationPreviousButton from 'components/NavigationPreviousButton';
import { useTranslation } from 'react-i18next';

export default function PolicyHead() {
	const { t } = useTranslation();
	return (
		<header className="relative flex items-center justify-center w-full">
			<NavigationPreviousButton className="absolute left-0 transform -translate-y-1/2 top-1/2" />
			<h1 className="text-lg font-semibold">{t('policy.title')}</h1>
		</header>
	);
}
