import { useTranslation } from 'react-i18next';
import { inputStyle, labelStyle } from '.';
import useSignupStore from 'store/useSignupStore';
import { useSignup } from 'hook/useSignup';
import clsx from 'clsx';

export default function PasswordField() {
	const { t } = useTranslation();
	const { password, setPassword } = useSignupStore();
	const { checkPasswordFormat } = useSignup();
	return (
		<div className="flex flex-col gap-y-2">
			<label htmlFor="password" className={labelStyle}>
				{t('signup.password_label')}
			</label>
			<input
				className={inputStyle}
				type="password"
				id="password"
				name="password"
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				placeholder={t('signup.password_placeholder')}
				required
			/>
			<p
				className={getPasswordConditionStyle(
					password.length,
					checkPasswordFormat(password)
				)}
			>
				{t('signup.password_condition')}
			</p>
		</div>
	);
}
//style
const getPasswordConditionStyle = (
	length: number,
	isValidatePassword: boolean
) =>
	clsx('text-sm', {
		'text-sub-text': length === 0,
		'text-green-500': isValidatePassword,
		'text-red-500': !isValidatePassword,
	});
