import { ReactComponent as SettingIcon } from 'asset/icon/setting.svg';
import HoleCounterSettingModal from 'components/modal/hole_counter/HoleCounterSettingModal';
import { useState } from 'react';
import { mainText } from 'utils/color';
export default function StrokeHoleCounterButton() {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);

	return (
		<>
			<button id="stroke-hole-counter-button" type="button" onClick={openModal}>
				<SettingIcon fill={mainText} />
			</button>
			<HoleCounterSettingModal isOpen={isOpen} onClose={closeModal} />
		</>
	);
}
