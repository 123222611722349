import customAxios from 'api';
import axios from 'axios';

type Params = {
	email: string;
	password: string;
	name: string;
	company: string;
	contactInformation: string;
};

const AlreadyExistString = 'Email is already in use';

type Response =
	| {
			accessToken: string;
	  }
	| typeof AlreadyExistString;
// 회원가입
export const userSignUp = async (params: Params) => {
	const { email, password } = params;
	try {
		const response = await customAxios.post<Response>(
			'/user/userSignUp.php',
			{
				name: params.name,
				company_name: params.company,
				contact_information: params.contactInformation,
			}, // body 데이터
			{
				headers: {
					Authorization: `Basic ${btoa(`${email}:${password}`)}`, // Basic Auth를 헤더에 추가
				},
			}
		);

		if (response.data === AlreadyExistString) {
			throw new Error('email_duplicate_fail');
		}
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unexpected error:', error);
		}
		throw error; // 오류를 호출한 함수로 전달
	}
};
