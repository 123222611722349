import { userLogout } from 'api/auth/userLogout';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function LogoutButton() {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const onClickLogout = async () => {
		try {
			await userLogout();
		} catch (error) {
			console.error('로그아웃 에러:', error);
		} finally {
			navigate('/');
		}
	};
	return (
		<button
			className="px-2.5 py-[14px] flex items-center justify-center border border-primary-blue text-primary-blue font-bold rounded"
			onClick={onClickLogout}
		>
			{t('profile.logout')}
		</button>
	);
}
