import CheckBox from 'components/common/CheckBox';
import { useTranslation } from 'react-i18next';
import useSignupStore from 'store/useSignupStore';
import { ReactComponent as LeftNav } from 'asset/icon/left-navigation.svg';
import { Link } from 'react-router-dom';

export default function AgreePolicyField() {
	const { t } = useTranslation();
	const { agreeToPrivacyPolicy, setAgreeToPrivacyPolicy } = useSignupStore();
	return (
		<div
			id="agree-policy-field"
			className="bg-[#EEECF3] rounded flex px-[14px] py-5 items-center justify-between"
		>
			<div className="flex gap-x-2.5 items-center">
				<button
					type="button"
					className="bg-white"
					onClick={() => setAgreeToPrivacyPolicy(!agreeToPrivacyPolicy)}
				>
					<CheckBox checked={agreeToPrivacyPolicy} />
				</button>

				<p className="text-sm">{t('signup.agree_policy')}</p>
			</div>
			<Link
				to="/policy"
				target="_blank"
				className="flex gap-x-2.5 items-center rotate-180"
			>
				<LeftNav />
			</Link>
		</div>
	);
}
