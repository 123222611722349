import { useTranslation } from 'react-i18next';
import FormHeader from './FormHeader';
import clsx from 'clsx';
import { useSignup } from 'hook/useSignup';
import FormBody from './FormBody';

export default function SignupForm() {
	const { t } = useTranslation();
	const { onSubmitSignup } = useSignup();
	return (
		<form id="sign-up-form" className={formStyle} onSubmit={onSubmitSignup}>
			<FormHeader />
			<FormBody />
		</form>
	);
}

// style
const formStyle = clsx(
	'bg-white border border-[#EEEEEE] rounded-[10px]',
	'p-6 w-[460px]',
	'flex flex-col gap-y-[50px]'
);
