import PasswordChangeStep1Box from 'components/password_change/PasswordChangeStep1Box';
import PasswordChangeStep2Box from 'components/password_change/PasswordChangeStep2Box';
import { useState } from 'react';

export default function ChangePasswordPage() {
	const [token, setToken] = useState<string | null>(null);

	return (
		<main className="flex flex-col items-center justify-center w-screen h-screen py-6">
			{!token ? (
				<PasswordChangeStep1Box setToken={setToken} />
			) : (
				<PasswordChangeStep2Box token={token} />
			)}
		</main>
	);
}
