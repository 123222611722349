import clsx from 'clsx';
import ReactSlider from 'react-slider';

type Props = {
	color: 'green' | 'red' | 'blue';
	value: number;
	setValue: (value: number) => void;
};

export default function RangeSlider({ color, value, setValue }: Props) {
	const maxValue = 100;

	return (
		<ReactSlider
			className="flex items-center flex-1"
			value={value}
			onChange={(newValue) => {
				setValue(newValue);
			}}
			renderTrack={(props, state) => (
				<div
					{...props}
					className={clsx('h-1.5 rounded-full', {
						'bg-[#42C67E]': state.index === 0 && color === 'green',
						'bg-[#FF684E]': state.index === 0 && color === 'red',
						'bg-[#6E64FF]': state.index === 0 && color === 'blue', // 진행된 트랙 색상
						'bg-sub-color': state.index === 1, // 배경 트랙 색상
					})}
				/>
			)}
			marks
			markClassName={markClassName}
			max={maxValue}
			min={0}
			renderThumb={(props, state) => (
				<div {...props} className={getThumbStyle(value)}>
					{/* <span className={thumbTextStyle}>{value}</span> */}
				</div>
			)}
		/>
	);
}
// style
const markClassName = clsx('w-2.5 h-2.5 rounded-full bg-subLine');
const getThumbStyle = (value: number) =>
	clsx('relative', 'w-[25px] h-[25px] rounded-full  bg-white border');
