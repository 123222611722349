import { useTranslation } from 'react-i18next';
import RangeSlider from './RangeSlider';
import { useEffect, useState } from 'react';
import NumberSpinner from './NumberSpinner';
import SaveButton from '../SaveButton';
import clsx from 'clsx';
import StrokeHoleCounterButton from './StrokeHoleCounterButton';
import { useMotorSetting } from 'hook/useMotorSetting';

export default function StrokeContainer() {
	const { t } = useTranslation();
	const { stroke, minStroke, maxStroke, onSubmitStroke } = useMotorSetting();
	const [value, setValue] = useState(stroke);
	const [minValue, setMinValue] = useState(minStroke);
	const [maxValue, setMaxValue] = useState(maxStroke);

	useEffect(() => {
		setValue(stroke);
		setMinValue(minStroke);
		setMaxValue(maxStroke);
	}, [stroke, minStroke, maxStroke]);

	const disable =
		stroke === value && minStroke === minValue && maxStroke === maxValue;

	return (
		<form
			className="flex gap-x-5 items-center justify-between px-5 py-1.5"
			onSubmit={(e) => {
				e.preventDefault();
				onSubmitStroke(minValue, maxValue, value);
			}}
		>
			<div className="flex gap-x-[15px] items-center">
				<div className="w-[80px] gap-x-1 flex justify-between items-center">
					<span className="font-bold">{t('actuator.strokes')}</span>
					<StrokeHoleCounterButton />
				</div>
				<div
					id="stroke-slider"
					className="w-[317px] h-[35px] flex-1 flex items-center gap-x-1"
				>
					<input
						className={minMaxStyle}
						value={minValue}
						onChange={(e) => {
							const newValue = parseInt(e.target.value);
							if (isNaN(newValue)) return setMinValue(0);
							setMinValue(newValue);
						}}
						onBlur={() => {
							if (minValue < 0) setMinValue(0);
							if (minValue > maxValue) setMinValue(maxValue);
						}}
					/>
					<RangeSlider color="green" value={value} setValue={setValue} />
					<input
						className={minMaxStyle}
						value={maxValue}
						onChange={(e) => {
							const newValue = parseInt(e.target.value);
							if (isNaN(newValue)) return setMaxValue(0);
							setMaxValue(newValue);
						}}
						onBlur={() => {
							if (maxValue < minValue) setMaxValue(minValue);
							if (maxValue > 100) setMaxValue(100);
						}}
					/>
				</div>
				<div className="border-sub-color border px-2.5 py-3 flex items-center justify-between  w-[100px] h-[35px]">
					<span className="text-sm font-bold text-sub-color">value</span>
					<input
						className="text-sm font-bold text-right w-7"
						type="text"
						value={value}
						min={minStroke}
						max={maxStroke}
						pattern="[0-9]*"
						onChange={(e) => {
							const newValue = parseInt(e.target.value);
							if (isNaN(newValue)) return setValue(0);
							setValue(newValue);
						}}
						onBlur={() => {
							if (value < 0) setValue(0);
							if (value > 100) setValue(100);
						}}
					/>
				</div>
				<NumberSpinner
					value={value}
					setValue={setValue}
					min={minStroke}
					max={maxStroke}
				/>
			</div>
			<SaveButton type="submit" id="stroke-save-button" disabled={disable} />
		</form>
	);
}

// style
const minMaxStyle = clsx(
	'text-sm text-center w-[50px] h-[35px] flex items-center justify-center py-3 px-2.5',
	'border border-sub-color'
);
