import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from 'asset/locale/en/translation.json';
import ko from 'asset/locale/ko/translation.json';

const userLanguage = window.navigator.language || window.navigator.language;

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: en,
		},
		ko: {
			translation: ko,
		},
	},
	lng: localStorage.getItem('language') || 'en', // 기본 언어 설정
	fallbackLng: 'en', // 사용자가 설정하지 않았을 때 기본 언어
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
