import {
	useGraphSettingsMutation,
	useGraphSettingsQuery,
} from 'query/useGraphSettingsQuery';
import { useState } from 'react';

export const useSpeedSettings = () => {
	const { data: graphSetting } = useGraphSettingsQuery();
	const { mutateAsync: setGraphSettings } = useGraphSettingsMutation();

	const id = graphSetting?.id ?? 0;
	const minSpeed = graphSetting?.min_speed ?? 0;
	const maxSpeed = graphSetting?.max_speed ?? 100;

	const [minSpeedValue, setMinSpeedValue] = useState<string>(`${minSpeed}`);
	const [maxSpeedValue, setMaxSpeedValue] = useState<string>(`${maxSpeed}`);

	const onChangeMin = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) < 0) {
			e.target.value = `${minSpeedValue}`;
			return;
		}
		setMinSpeedValue(value);
	};

	const onChangeMax = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) < 0) {
			e.target.value = `${maxSpeedValue}`;
			return;
		}
		setMaxSpeedValue(value);
	};

	const onSubmit = () => {
		try {
			if (Number(minSpeedValue) < Number(maxSpeedValue)) {
				setGraphSettings({
					id,
					min_speed: Number(minSpeedValue),
					max_speed: Number(maxSpeedValue),
				});
			} else {
				setGraphSettings({
					id,
					min_speed: Number(maxSpeedValue),
					max_speed: Number(minSpeedValue),
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
	const disabled =
		(minSpeedValue === `${minSpeed}` && maxSpeedValue === `${maxSpeed}`) ||
		minSpeedValue === '' ||
		maxSpeedValue === '' ||
		Number(minSpeedValue) < 0 ||
		Number(maxSpeedValue) < 0 ||
		Number(minSpeedValue) > Number(maxSpeedValue);

	return {
		id,
		minSpeed,
		maxSpeed,
		minSpeedValue,
		maxSpeedValue,
		onChangeMin,
		onChangeMax,
		onSubmit,
		disabled,
	};
};
