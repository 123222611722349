import customAxios from 'api';

export const deleteMotorSetting = async (id: number) => {
	try {
		const response = await customAxios.delete(
			`/hardware/motorUserSetting.php`,
			{
				data: {
					id,
				},
			}
		);
		return response;
	} catch (error) {
		throw error;
	}
};
