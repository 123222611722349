import clsx from 'clsx';
import DropDown from 'components/common/DropDown';
import { ReactComponent as Polygon } from 'asset/icon/polygon.svg';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilledCheckBox from 'components/common/FilledCheckBox';
import ConfrimModal from 'components/modal/ConfirmModal';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};
export default function OperationDropdown({ isOpen, onClose }: Props) {
	const { t } = useTranslation();
	//서버에서 받아오는 user에 등록된 actuator 목록
	const actuatorList = [1, 2];
	const [selectedActuatorList, setSelectedActuatorList] = useState<number[]>(
		[]
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const onClickActuator = (actuatorId: number) => {
		if (selectedActuatorList.includes(actuatorId)) {
			setSelectedActuatorList(
				selectedActuatorList.filter((id) => id !== actuatorId)
			);
		} else {
			setSelectedActuatorList([...selectedActuatorList, actuatorId]);
		}
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsModalOpen(true);
	};
	return (
		<>
			<DropDown isOpen={isOpen} onClose={onClose}>
				<form
					className="px-[14px] py-3 flex flex-col gap-y-2.5 w-[179px]"
					onSubmit={onSubmit}
				>
					{actuatorList.map((actuatorId, index) => (
						<div
							key={`actuator-${index}`}
							className="flex gap-x-2.5 items-center"
						>
							<button onClick={() => onClickActuator(actuatorId)} type="button">
								<FilledCheckBox
									checked={selectedActuatorList.includes(actuatorId)}
								/>
							</button>
							<span>
								{t('actuator.actuator_number', { number: actuatorId })}
							</span>
						</div>
					))}
					<button
						className={submitButton}
						type="submit"
						disabled={selectedActuatorList.length === 0}
					>
						<span>{t('header.activation')}</span>
						<Polygon fill="white" />
					</button>
				</form>
			</DropDown>
			<ConfrimModal
				isOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				description={t('actuator.operation.confirm_description')}
				onConfirm={() => {
					setIsModalOpen(false);
				}}
			/>
		</>
	);
}

//style
const submitButton = clsx(
	'px-4 py-2.5 bg-primary-blue rounded',
	'flex items-center justify-center gap-x-[5px]',
	'text-white font-bold',
	'disabled:opacity-30 disabled:cursor-not-allowed'
);
