import customAxios from 'api';
import axios from 'axios';

type Response = {
	message: string;
	password_reset_token: string;
};

export const verifyPassword = async (password: string) => {
	try {
		const response = await customAxios.post<Response>(
			'/user/verifyPassword.php',
			{
				current_password: password,
			}
		);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unexpected error:', error);
		}
		throw error; // 오류를 호출한 함수로 전달
	}
};
