import { ReactComponent as SignUpIcon } from 'asset/icon/signup.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { primaryBlue } from 'utils/color';
export default function SignUpSuccessPage() {
	const { t } = useTranslation();
	return (
		<main className="flex flex-col items-center justify-center h-screen">
			<div
				id="signup-success-box"
				className="w-[460px] p-6 flex flex-col bg-white"
			>
				<div className="flex flex-col gap-y-[50px] items-center">
					<div id="signup-empty" />
					<div className="flex flex-col gap-y-2.5 items-center">
						<SignUpIcon fill={primaryBlue} />
						<h1>{t('signup.success.title')}</h1>
					</div>
					<Link
						to="/actuator"
						className="w-full flex justify-center items-center bg-primary-blue rounded px-2.5 py-[14px]"
					>
						<span className="font-extrabold text-white">
							{t('signup.success.button')}
						</span>
					</Link>
				</div>
			</div>
		</main>
	);
}
