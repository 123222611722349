import clsx from 'clsx';
import { useMotorInfoQuery } from 'query/useMotorInfoQuery';
import { useTranslation } from 'react-i18next';
import useActuatorSelectionStore from 'store/useSelectedMotorModel';

export default function ActuatorModelSidebar() {
	const { t } = useTranslation();
	const { selectedPartner, selectedModelId, setSelectedModelId } =
		useActuatorSelectionStore();
	const { data } = useMotorInfoQuery();

	if (!data) return null;
	if (!selectedPartner)
		return (
			<div className="w-[170px] text-sub-text flex items-center justify-center">
				{t('actuator.select_modal.not_selected_partner')}
			</div>
		);
	const { motorInfoByPartner } = data;

	const motorInfos = motorInfoByPartner[selectedPartner];

	return (
		<div id="actuator-modal-actuator-list" className="w-[170px] flex flex-col">
			{motorInfos.map((motor, index) => (
				<button
					key={index}
					className={clsx(
						'hover:bg-slate-100 p-2.5 gap-x-2.5 flex items-center',
						{ 'bg-selected-gray': motor.id === selectedModelId }
					)}
					onClick={() => setSelectedModelId(motor.id)}
				>
					<img
						width={32}
						height={32}
						className="object-cover rounded-full h-[32px]"
						src={motor.model_image}
						alt={motor.model_name}
					/>
					<span>{motor.model_name}</span>
				</button>
			))}
		</div>
	);
}
