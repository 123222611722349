import { useTranslation } from 'react-i18next';
import { buttonStyle } from '.';
import { ReactComponent as ImportIcon } from 'asset/icon/import.svg';
import { refreshAccessToken } from 'api/auth/refresh';
import useAuthStore from 'store/useAuthStore';
export default function PresetSaveButton() {
	const { t } = useTranslation();

	const { setAccessToken } = useAuthStore();
	// Access Token 갱신
	const onClick = async () => {
		try {
			const response = await refreshAccessToken();
			console.log(response);
			setAccessToken(response);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<button className={buttonStyle} onClick={onClick}>
			<ImportIcon className="rotate-180" />
			<span className="text-sm font-bold">{t('actuator.preset_save')}</span>
		</button>
	);
}
