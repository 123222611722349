type Props = {
	onClick: () => void;
	className?: string;
};

export default function CloseCrossButton({ onClick, className }: Props) {
	return (
		<button onClick={onClick} className={className}>
			<svg
				width="30"
				height="30"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M1 1L29 29"
					stroke="#7D7B7B"
					stroke-width="2"
					stroke-linecap="round"
				/>
				<path
					d="M29 1L0.999999 29"
					stroke="#7D7B7B"
					stroke-width="2"
					stroke-linecap="round"
				/>
			</svg>
		</button>
	);
}
