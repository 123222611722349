import { StylesConfig } from 'react-select';
import { mainText, primaryBlue, subColor, subText } from 'utils/color';

// react select style
export const selectStyle: StylesConfig<
	{ value: string; label: string },
	false
> = {
	option: (provided, state) => ({
		...provided,
		opacity: 0.8,
		backgroundColor: state.isFocused ? primaryBlue : 'transparent',
		color: state.isFocused ? 'white' : 'inherit',
		fontSize: 14,
		fontWeight: 500,
	}),
	control: (provided, state) => ({
		...provided,
		border: `1px solid ${state.isFocused ? primaryBlue : subColor}`,
		paddingRight: 0,
		fontSize: 14,
		width: 140,
	}),
	singleValue: (provided) => ({
		...provided,
	}),
	valueContainer: (provided) => ({
		...provided,
	}),
	placeholder: (provided) => ({
		...provided,
		color: '#7D7B7B',
		fontSize: 14,
		fontWeight: 400,
	}),
	indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
};
