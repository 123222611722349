import { useTranslation } from 'react-i18next';

type Props = {
	onClose: () => void;
};
export default function ModalCancelButton({ onClose }: Props) {
	const { t } = useTranslation();

	return (
		<button
			className="rounded px-2.5 py-[14px] flex items-center justify-center border border-primary-blue w-[150px] h-[47px]"
			onClick={onClose}
		>
			<span className="font-bold text-primary-blue">
				{t('communication.cancel')}
			</span>
		</button>
	);
}
