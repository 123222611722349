import React, { useEffect } from 'react';
import './App.css';
import ActuatorPage from './pages/ActuatorPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProfilePage from 'pages/ProfilePage';
import CancelAccountPage from 'pages/CancelAccountPage';
import ChangePasswordPage from 'pages/ChangePasswordPage';
import LoginPage from 'pages/LoginPage';
import PasswordForgotPage from 'pages/PasswordForgotPage';
import SignupPage from 'pages/SignupPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage';

import useAuthStore from 'store/useAuthStore';
import SignUpSuccessPage from 'pages/SignUpSuccessPage';
import ResetPage from 'pages/ResetPage';
import ResetResultPage from 'pages/ResetResultPage';
import PrivateRoute from 'components/PrivateRoute';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { reactQueryOption } from 'query';
import CancelAccountSuccessPage from 'pages/CancelAccountSuccessPage';

const queryClient = new QueryClient({ defaultOptions: reactQueryOption });

function App() {
	const { initializeAuth } = useAuthStore();

	useEffect(() => {
		// 자동 로그인 초기화 로직
		initializeAuth();
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<LoginPage />} />
					<Route path="/sign-up" element={<SignupPage />} />
					<Route path="/signup-success" element={<SignUpSuccessPage />} />
					<Route path="/policy" element={<PrivacyPolicyPage />} />
					<Route path="/forget" element={<PasswordForgotPage />} />
					<Route path="/reset" element={<ResetPage />} />
					<Route path="/reset-result" element={<ResetResultPage />} />

					{/* 보호된 경로 */}
					<Route
						path="/actuator"
						element={
							<PrivateRoute>
								<ActuatorPage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/profile"
						element={
							<PrivateRoute>
								<ProfilePage />
							</PrivateRoute>
						}
					/>
					{/* cancel-accout와 cancel-accout/success */}
					<Route
						path="/cancel-account"
						element={
							<PrivateRoute>
								<CancelAccountPage />
							</PrivateRoute>
						}
					/>

					<Route
						path="/cancel-account/success"
						element={<CancelAccountSuccessPage />}
					/>
					<Route
						path="/password-reset"
						element={
							<PrivateRoute>
								<ChangePasswordPage />
							</PrivateRoute>
						}
					/>
				</Routes>
			</BrowserRouter>
		</QueryClientProvider>
	);
}

export default App;
