import { useTranslation } from 'react-i18next';

type Props = {
	status: 'fail' | 'loading' | 'error' | 'none';
};

export default function LoginResultStatus({ status }: Props) {
	const { t } = useTranslation();
	if (status === 'fail')
		return (
			<p className="text-sm text-center text-red-500">
				{t('login.error.login_fail')}
			</p>
		);
	if (status === 'error')
		return (
			<p className="text-sm text-center text-red-500">
				{t('login.error.login_error')}
			</p>
		);
	return null;
}
