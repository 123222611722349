import { useTranslation } from 'react-i18next';
import { getButtonStyle } from '.';
import CheckBox from 'components/common/CheckBox';
import useActuatorValueStore from 'store/useActuatorValueStore';

export default function PushButton() {
	const { t } = useTranslation();
	const { switchMode, setSwitchMode } = useActuatorValueStore();
	const checked = switchMode === 'push';
	const onClick = () => {
		setSwitchMode(checked ? null : 'push');
	};
	return (
		<button className={getButtonStyle(checked)} onClick={onClick}>
			<CheckBox checked={checked} />
			<span className="text-sm line-clamp-2">
				{t('actuator.switch_mode.push')}
			</span>
		</button>
	);
}
