import React from 'react';

//utils
export function parseNewlines(text: string): React.ReactNode {
	return text.split('\n').map((line, index) => (
		<React.Fragment key={index}>
			{line}
			<br />
		</React.Fragment>
	));
}
