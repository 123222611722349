import { useTranslation } from 'react-i18next';
import PushLockButton from './PushLockButton';
import PushButton from './PushButton';

export default function SwitchModeArea() {
	const { t } = useTranslation();
	return (
		<div className="flex items-center gap-x-5 px-5 py-[14px]">
			<span className="text-sm font-bold">
				{t('actuator.switch_mode.title')}
			</span>
			<div className="flex items-center gap-x-2.5">
				<PushButton />
				<PushLockButton />
			</div>
		</div>
	);
}
