import clsx from 'clsx';

export type GraphData = {
	time: number;
	stroke: number;
	speed: number;
	detection: number;
};
export const strokeColor = '#05D105';
export const speedColor = '#FF684E';
export const detectionColor = '#1305D1';

export const graphInputContainerStyle = clsx(
	'border border-sub-color px-[14px] py-[9px] w-[144px] ',
	'flex justify-between items-center'
);
export const inputStyle = clsx('border-none bg-transparent w-full');
export const unitStyle = clsx(
	'w-[24px] text-left text-sm font-medium text-sub-text'
);
