import customAxios from 'api';

type Response = {
	message: string;
};

export const patchProfile = async (data: {
	name?: string;
	companyName?: string;
	contactInformation?: string;
}) => {
	try {
		const response = await customAxios.patch<Response>(
			'/user/userProfile.php',
			data
		);
		return response.data;
	} catch (error) {
		throw error;
	}
};
