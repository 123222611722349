import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GRAPH_SETTING_KEY } from './keys';
import { getGraphSetting } from 'api/graph/getGraph';
import { patchGraph } from 'api/graph/patchGraph';

// 그래프 설정을 가져오는 쿼리
export const useGraphSettingsQuery = () => {
	return useQuery({
		queryKey: [GRAPH_SETTING_KEY],
		queryFn: async () => {
			const result = await getGraphSetting();
			return result[0];
		},
	});
};

//그래프 설정을 변경하는 뮤테이션
export const useGraphSettingsMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: {
			id: number;
			min_stroke?: number;
			max_stroke?: number;
			min_speed?: number;
			max_speed?: number;
			min_detection?: number;
			max_detection?: number;
		}) => {
			const result = await patchGraph({ ...data });
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries([GRAPH_SETTING_KEY]);
		},
	});
};
