import { contentsStyle, labelStyle } from './style';

export default function HallSensorSpec() {
	const hallCounter = 0;
	return (
		<div className="flex flex-col flex-1 gap-y-2">
			<span className={labelStyle}>Hall Sensor</span>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Hall counter:</span>
				<span className={contentsStyle}>{hallCounter}</span>
			</div>
		</div>
	);
}
