import clsx from 'clsx';
import { ReactComponent as PlayIcon } from 'asset/icon/play-polygon.svg';
import { ReactComponent as Paused } from 'asset/icon/paused.svg';

type Props = {
	action: 'playing' | 'paused';
};

export default function ActuatorPlayButton({ action }: Props) {
	return (
		<button className={getPlayButtonStyle(action)}>
			{action === 'playing' ? <PlayIcon /> : <Paused />}
		</button>
	);
}

const getPlayButtonStyle = (status: 'playing' | 'paused') =>
	clsx('rounded-full w-[42px] h-[42px]', 'flex items-center justify-center', {
		'bg-primary-blue': status === 'playing',
		'bg-white': status === 'paused',
	});
