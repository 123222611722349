import { ReactComponent as EditIcon } from 'asset/icon/edit.svg';
import TextInputModal from 'components/modal/TextInputModal';
import {
	usePatchProfileMutation,
	useProfileQuery,
} from 'query/useProfileQuery';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
	type: 'name' | 'companyName' | 'contact';
};

export default function ProfileEditButton({ type }: Props) {
	const { t } = useTranslation();
	const { data } = useProfileQuery();
	const { mutateAsync: patchProfile } = usePatchProfileMutation();
	const [isOpen, setIsOpen] = useState(false);
	const label =
		type === 'name'
			? t('profile.name')
			: type === 'companyName'
			? t('profile.company_name')
			: t('profile.contact_information');

	const defaultValue =
		type === 'name'
			? data?.name
			: type === 'companyName'
			? data?.company_name
			: data?.contact_information;

	const onSubmit = async (value: string) => {
		try {
			type === 'name' && (await patchProfile({ name: value }));
			type === 'companyName' && (await patchProfile({ company_name: value }));
			type === 'contact' &&
				(await patchProfile({ contact_information: value }));
		} catch (error) {
			console.error(error);
		}
		setIsOpen(false);
	};

	return (
		<>
			<button onClick={() => setIsOpen(true)}>
				<EditIcon />
			</button>
			<TextInputModal
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				label={label}
				onSubmit={async (value) => await onSubmit(value)}
				defaultValue={defaultValue}
			/>
		</>
	);
}
