import SelectActuatorModal from 'components/modal/select-actuator/SelectActuatorModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function ActuatorSelectButton() {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<button onClick={() => setIsOpen(true)}>
				<span className="font-semibold">{t('header.information')}</span>
			</button>
			<SelectActuatorModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
		</>
	);
}
