import { useTranslation } from 'react-i18next';
import { inputStyle, labelStyle } from '.';
import useSignupStore from 'store/useSignupStore';
import clsx from 'clsx';

export default function PasswordConfirmField() {
	const { t } = useTranslation();
	const {
		password,
		passwordMatchStatus,
		setPasswordMatchStatus,
		setConfirmPassword,
	} = useSignupStore();

	const onChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
		setConfirmPassword(e.target.value);
		if (e.target.value === password) {
			setPasswordMatchStatus('match');
		} else {
			setPasswordMatchStatus('notMatch');
		}
	};

	return (
		<div className="flex flex-col gap-y-2">
			<label htmlFor="password-confirm" className={labelStyle}>
				{t('signup.password_confirm_label')}
			</label>
			<input
				className={inputStyle}
				type="password"
				id="password-confirm"
				name="password-confirm"
				onChange={onChangePasswordConfirm}
				placeholder={t('signup.password_confirm_placeholder')}
				required
			/>
			<p className={getMatchStatusStyle(passwordMatchStatus)}>
				{passwordMatchStatus === 'notMatch'
					? t('signup.password_not_match')
					: passwordMatchStatus === 'match'
					? t('signup.password_match')
					: ''}
			</p>
		</div>
	);
}

// style
const getMatchStatusStyle = (status: string) =>
	clsx('text-sm', {
		'text-green-500': status === 'match',
		'text-red-500': status === 'notMatch',
	});
