import Select from 'react-select';
import { selectStyle } from '.';
import { ReactComponent as ArrowDown } from 'asset/icon/arrow-down.svg';
export default function ParitySelect() {
	return (
		<Select
			styles={selectStyle}
			isSearchable={false}
			components={{
				IndicatorsContainer: () => (
					<div className="px-3">
						<ArrowDown stroke="#0B7DFF" />
					</div>
				),
			}}
		/>
	);
}
