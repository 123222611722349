import { useQuery } from '@tanstack/react-query';
import { MOTOR_INFO_KEY } from './keys';
import { getMotorInfos, MotorInfo } from 'api/motor/getMotorInfo';

export const useMotorInfoQuery = () => {
	return useQuery({
		queryKey: [MOTOR_INFO_KEY],
		queryFn: async () => {
			const result = await getMotorInfos();

			//중복을 제거한 partners 목록
			const partners: string[] = Array.from(
				new Set(result.map((item) => item.partner))
			);
			// partners 목록을 기준으로 motorInfo를 분류하여 Object로 만듦
			//key: Partner, value: MotorInfo[]
			const motorInfoByPartner: Record<string, MotorInfo[]> = partners.reduce(
				(acc, partner) => {
					const motorInfo = result.filter((item) => item.partner === partner);
					return { ...acc, [partner]: motorInfo };
				},
				{}
			);

			return {
				partners,
				motorInfoByPartner,
				motorInfos: result,
			};
		},
	});
};
