import { useQueryClient } from '@tanstack/react-query';
import { userLogin } from 'api/auth/userLogin';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuthStore from 'store/useAuthStore';

export const useLogin = () => {
	const navigate = useNavigate();
	const { setAccessToken } = useAuthStore();
	const [loginResult, setLoginResult] = useState<
		'fail' | 'loading' | 'error' | 'none'
	>('none');
	const queryClient = useQueryClient();

	const onLogin = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const email = e.currentTarget.email.value;
		const password = e.currentTarget.password.value;
		//로그인 요청 시에 쿼리 클리어
		try {
			// 로그인 요청
			setLoginResult('loading');
			const loginReseult = await userLogin({
				email: email,
				password: password,
			});
			if (loginReseult === false) {
				return setLoginResult('fail');
			}
			const accessToken = loginReseult.accessToken;
			navigate('/actuator');
			setLoginResult('none');
			setAccessToken(accessToken);
		} catch (e) {
			console.log(e);
			setLoginResult('error');
		} finally {
			queryClient.clear();
		}
	};
	return { onLogin, loginResult };
};
