import customAxios from 'api';

type Params = {
	email: string;
};

type Response = {
	success: boolean;
	isDuplicate: boolean;
	message: string;
};

//이메일 확인
export const isEmailDuplicate = async (params: Params) => {
	const { email } = params;
	try {
		const response = await customAxios.post<Response>(
			'/user/userIsEmailDuplicate.php',
			{
				email,
			} // body 데이터
		);
		return response;
	} catch (error) {
		console.error('Unexpected error:', error);
		throw error; // 오류를 호출한 함수로 전달
	}
};
