import useActuatorValueStore from 'store/useActuatorValueStore';

export default function useVariableSpeed(
	rangeNumber: number //구간 번호
) {
	const { minStroke, maxStroke, positionArray, speedArray, setPositionArray } =
		useActuatorValueStore();

	const currentPosition = positionArray[rangeNumber]; //현재 position
	const previousPosition = positionArray[rangeNumber - 1]; //이전 position
	const onBlurPosition = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) <= 0 || Number(value) > 100) {
			e.target.value = `${currentPosition}`;
			return;
		}
		//이전 position이 현재 position보다 크거나 같음 혹은 다음 position이 현재 position보다 작거나 같은 경우, 변경 불가
		if (
			(previousPosition >= Number(value) && rangeNumber !== 0) ||
			(positionArray[rangeNumber + 1] <= Number(value) &&
				rangeNumber !== positionArray.length - 1)
		)
			return (e.target.value = `${currentPosition}`);

		// 현재 position을 변경
		setPositionArray(
			positionArray.map((position, index) =>
				index === rangeNumber ? Number(value) : position
			)
		);
	};

	const onBlurSpeed = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		if (isNaN(Number(value)) || Number(value) <= 0) {
			e.target.value = `${speedArray[rangeNumber]}`;
			return;
		}
		// speed 변경
		speedArray[rangeNumber] = Number(value);
		setPositionArray([...positionArray]);
	};

	const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.currentTarget.blur();
		}
	};

	//현재 stroke
	const currentStroke = Math.floor(
		(currentPosition / 100) * (maxStroke - minStroke)
	);
	//이전 stroke
	const previousStroke =
		Math.floor((previousPosition / 100) * (maxStroke - minStroke)) +
		(previousPosition === 0 ? 0 : 1);

	return {
		currentStroke,
		previousStroke,
		currentPosition,
		previousPosition,
		onBlurPosition,
		onBlurSpeed,
		onEnter,
		speedArray,
	};
}
