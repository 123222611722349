import clsx from 'clsx';

export const getButtonStyle = (checked: boolean) =>
	clsx(
		'flex px-[14px] py-3 rounded-[10px] items-center gap-x-2.5 border w-[170px] h-[50px]',
		{
			'bg-primary-500 text-white border-primary-blue bg-primary-blue': checked,
			'bg-white border-sub-color': !checked,
		},
		'text-start'
	);
