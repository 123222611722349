import { axiosConfig } from 'api';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import useAuthStore from 'store/useAuthStore';
import { userLogout } from './userLogout';

type RefreshResponse = {
	accessToken: string;
};

export async function refreshAccessToken() {
	// access token이 만료되었을 때 사용할 새로운 Axios 인스턴스를 생성한다.
	const refreshAxiosConfig: AxiosRequestConfig = {
		...axiosConfig,
		headers: {
			...axiosConfig.headers,
		},
		withCredentials: true, // 쿠키 포함
	};

	const refreshAxios: AxiosInstance = axios.create(refreshAxiosConfig);

	refreshAxios.interceptors.response.use(
		(response) => response,
		async (error) => {
			if (error.response?.status === 401) {
				// refresh token이 만료되었을 때 로그아웃 처리
				useAuthStore.getState().setAccessToken(null);
				await userLogout();
				return Promise.reject(error);
			}
			return Promise.reject(error);
		}
	);

	const response = await refreshAxios.get<RefreshResponse>(
		'/user/userRenewAccessToken.php'
	);
	return response.data.accessToken;
}
