import { useTranslation } from 'react-i18next';
import { buttonStyle } from '.';
import useOperationGraphStore from 'store/useOperationGraphStore';
import { detectionColor } from '..';
import { ReactComponent as CheckIcon } from 'asset/icon/legend-check.svg';

export default function DetectionLegendButton() {
	const { t } = useTranslation();
	const { isDetectionActive, setIsDetectionActive } = useOperationGraphStore();

	return (
		<button
			className={buttonStyle}
			onClick={() => setIsDetectionActive(!isDetectionActive)}
		>
			<div
				className="w-[14px] h-[14px] rounded-sm flex items-center justify-center"
				style={{
					backgroundColor: isDetectionActive ? detectionColor : 'white',
					border: isDetectionActive ? 'none' : `1px solid ${detectionColor}`,
				}}
			>
				{isDetectionActive && <CheckIcon />}
			</div>
			{t('actuator.operation_graph.detection')}
		</button>
	);
}
