export const isValidateEmail = (email: string) => {
	const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
	return emailRegexp.test(email);
};

//비밀번호 format 체크: Min of 8 characters, with a numbers, and special characters.
export const checkPasswordFormat = (password: string) => {
	const passwordFormat =
		/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
	return passwordFormat.test(password);
};
