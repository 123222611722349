import customAxios from 'api';

type Payload = {
	password: string;
};
//password 변경
const userResetPasswordConfirm = async (payload: Payload, token: string) => {
	const response = await customAxios.patch(
		'/user/userResetPasswordConfirm.php',
		payload,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	return response.data;
};

export default userResetPasswordConfirm;
