import clsx from 'clsx';
import useVariableSpeed from 'hook/useVariableSpeed';

type Props = {
	rangeNumber: number;
};

export default function SpeedTableItem({ rangeNumber }: Props) {
	const {
		currentStroke,
		previousStroke,
		currentPosition,
		onBlurPosition,
		onBlurSpeed,
		onEnter,
		speedArray,
	} = useVariableSpeed(rangeNumber);
	return (
		<div className="grid grid-rows-4 divide-y divide-white">
			<div className="flex items-center justify-center text-sm font-semibold text-white bg-primary-blue">
				{`S${rangeNumber}`}
			</div>
			<div className="text-sm font-semibold text-left text-white bg-main-text px-[14px] py-2">
				{`${previousStroke}~${currentStroke}mm`}
			</div>
			<div className=" text-sm text-left bg-white px-[14px] py-2">
				<input
					className={inputStyle}
					defaultValue={currentPosition}
					onBlur={onBlurPosition}
					onKeyDown={onEnter}
				/>
			</div>
			<div className=" text-sm text-left bg-white px-[14px] py-2">
				<input
					className={inputStyle}
					defaultValue={speedArray[rangeNumber]}
					onBlur={onBlurSpeed}
					onKeyDown={onEnter}
				/>
			</div>
		</div>
	);
}

//style
const inputStyle = clsx('border-none bg-transparent w-full');
