import { create } from 'zustand';

type SelectedMotorModelState = {
	selectedPartner: string | null; // 선택한 Partner
	selectedModelId: number | null; // 선택한 Actuator 모델
	setSelectedPartner: (partner: string) => void;
	setSelectedModelId: (modelId: number) => void;
	resetSelection: () => void; // 선택 초기화
};

const useSelectedMotorModelStore = create<SelectedMotorModelState>((set) => ({
	selectedPartner: null,
	selectedModelId: null,
	setSelectedPartner: (partner) =>
		set({ selectedPartner: partner, selectedModelId: null }), // 모델 초기화
	setSelectedModelId: (modelId) => set({ selectedModelId: modelId }),
	resetSelection: () => set({ selectedPartner: null, selectedModelId: null }),
}));

export default useSelectedMotorModelStore;
