import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useActuatorValueStore from 'store/useActuatorValueStore';

export default function SpeedModeToggle() {
	const { t } = useTranslation();
	const { speedMode, setSpeedMode } = useActuatorValueStore();
	const ref = useRef<HTMLButtonElement>(null);
	const buttonWidth = ref.current?.offsetWidth || 55;
	return (
		<div className="flex gap-x-1.5 items-center">
			<button
				className={getTextStyle(speedMode === 'variable')}
				onClick={() => setSpeedMode('variable')}
				ref={ref}
			>
				{t('actuator.speed_control.variable_speed')}
			</button>

			<button
				id="speed-mode-toggle"
				className="relative w-[55px] bg-primary-blue rounded-full h-[30px]"
				onClick={() =>
					setSpeedMode(speedMode === 'variable' ? 'single' : 'variable')
				}
			>
				<div
					id="toggle-handle"
					className={toggleHandleStyle}
					style={{
						transform:
							speedMode === 'variable'
								? ''
								: `translateX(${buttonWidth / 2}px)`,
					}}
				/>
			</button>
			<button
				className={getTextStyle(speedMode === 'single')}
				onClick={() => setSpeedMode('single')}
			>
				{t('actuator.speed_control.single_speed')}
			</button>
		</div>
	);
}
// style
const getTextStyle = (selected: boolean) =>
	clsx('w-[55px] text-xs font-bold', {
		'opacity-30': !selected,
	});

const toggleHandleStyle = clsx(
	'relative w-5 h-5 bg-white rounded-full left-[4px] transition-transform'
);
