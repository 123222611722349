import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { PROFILE_KEY } from './keys';
import { getProfile } from 'api/profile/getProfile';
import { patchProfile } from 'api/profile/patchProfile';

export const useProfileQuery = () => {
	return useQuery({
		queryKey: [PROFILE_KEY],
		queryFn: async () => {
			const result = await getProfile();
			return result;
		},
	});
};

//patch profile mutation
export const usePatchProfileMutation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: {
			name?: string;
			company_name?: string;
			contact_information?: string;
		}) => {
			const result = await patchProfile({ ...data });
			return result;
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries([PROFILE_KEY]);
		},
	});
};
