import PasswordForgotBox from 'components/forget/PasswordForgetBox';
import { useEffect } from 'react';
import useForgetStore from 'store/useForgetStore';

export default function PasswordForgotPage() {
	//이메일, 이름 초기화
	const { init } = useForgetStore();
	useEffect(() => {
		init();
	}, [init]);

	return (
		<main className="flex flex-col items-center justify-center h-screen">
			<PasswordForgotBox />
		</main>
	);
}
