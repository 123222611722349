import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from 'recharts';
import { detectionColor, GraphData, speedColor, strokeColor } from '.';
import useOperationGraphStore from 'store/useOperationGraphStore';
import { useGraphSettingsQuery } from 'query/useGraphSettingsQuery';

export default function ActuatorGraph() {
	const data = generateRandomData();

	const { data: graphSetting } = useGraphSettingsQuery();

	const minStroke = graphSetting?.min_stroke || 0;
	const maxStroke = graphSetting?.max_stroke || 130;
	const minSpeed = graphSetting?.min_speed || 0;
	const maxSpeed = graphSetting?.max_speed || 60;
	const minDetection = graphSetting?.min_detection || 0;
	const maxDetection = graphSetting?.max_detection || 80;

	const { isSpeedActive, isStrokeActive, isDetectionActive } =
		useOperationGraphStore();

	return (
		<ResponsiveContainer width="100%" height="100%">
			<LineChart
				width={500}
				height={300}
				data={data}
				margin={{
					top: 10,
					right: 5,
					left: 5,
					bottom: 0,
				}}
				{...{ overflow: 'visible' }}
			>
				<Line
					yAxisId="left-stroke"
					type="linear"
					dataKey="stroke"
					stroke={strokeColor}
					dot={false}
					hide={!isStrokeActive}
				/>
				<Line
					yAxisId="left-speed"
					type="linear"
					dataKey="speed"
					stroke={speedColor}
					dot={false}
					hide={!isSpeedActive}
				/>

				<Line
					yAxisId="right"
					type="linear"
					dataKey="detection"
					stroke={detectionColor}
					dot={false}
					hide={!isDetectionActive}
				/>
				<YAxis
					yAxisId="left-stroke"
					orientation="left"
					dataKey="stroke"
					width={30}
					className="text-[9px]"
					tickCount={11}
					interval={0}
					opacity={isStrokeActive ? 1 : 0.3}
					label={{
						value: 'Stroke',
						position: 'top',
						offset: 10,
					}}
					allowDataOverflow
					domain={[minStroke, maxStroke]}
				/>
				<YAxis
					yAxisId="left-speed"
					orientation="left"
					dataKey="speed"
					width={30}
					className="text-[9px]"
					tickCount={11}
					interval={0}
					opacity={isSpeedActive ? 1 : 0.3}
					allowDataOverflow
					label={{
						value: 'Speed',
						position: 'top',
						offset: 10,
					}}
					domain={[minSpeed, maxSpeed]}
				/>
				<YAxis
					yAxisId="right"
					orientation="right"
					dataKey="detection"
					width={24}
					className="text-[9px]"
					tickCount={11}
					interval={0}
					opacity={isDetectionActive ? 1 : 0.3}
					allowDataOverflow
					label={{
						value: 'Detection',
						position: 'top',
						offset: 10,
					}}
					domain={[minDetection, maxDetection]}
				/>
				<Tooltip />
			</LineChart>
		</ResponsiveContainer>
	);
}
const generateRandomData = (): GraphData[] => {
	const data: GraphData[] = [];
	for (let i = 0; i <= 12; i++) {
		data.push({
			time: i,
			stroke: Math.floor(Math.random() * 131),
			speed: Math.floor(Math.random() * 61),
			detection: Math.floor(Math.random() * 81),
		});
	}
	return data;
};

// 첫번째 알파벳을 대문자로 바꾸기
export function capitalizeFirstLetter(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}
