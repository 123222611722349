import NavigationPreviousButton from 'components/NavigationPreviousButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import WithdrawConfirmButton from './WithdrawConfirmButton';
import WithdrawCancelButton from './WithdrawCancelButton';
import CancelAgreement from './agreement/CancelAgreement';
import useCancelAgreementStore from 'store/useCancleAgreementStore';

export default function CancelAccountBox() {
	const { t } = useTranslation();
	const { isAgreed } = useCancelAgreementStore();
	return (
		<section className="p-6 bg-white rounded-[10px] w-[460px] border border-[#EEEEEE]">
			<div id="profile-box-header" className="flex flex-col gap-y-[30px]">
				<div className="relative flex justify-center">
					<NavigationPreviousButton className="absolute left-0 transform -translate-y-1/2 top-1/2" />
					<span className="text-lg font-bold">{t('account_cancel.title')}</span>
				</div>
				<div className="flex flex-col gap-y-4">
					<p className="font-bold">{t('account_cancel.sub_title')}</p>
					<ul className="pl-6 list-inside">
						<li className="list-disc -indent-6 text-sub-text">
							{t('account_cancel.description')}
						</li>
					</ul>
				</div>
				<CancelAgreement />
				<div
					id="cancle-account-button-box"
					className="flex gap-x-[15px] items-center"
				>
					<WithdrawCancelButton />
					<WithdrawConfirmButton disabled={!isAgreed} />
				</div>
			</div>
		</section>
	);
}

//
const CheckBox = ({ checked }: { checked: boolean }) => {
	return (
		<div
			className={clsx(
				'w-[17px] h-[17px] rounded-sm flex items-center justify-center',
				{
					'bg-primary-blue': checked,
					'border border-sub-color bg-white': !checked,
				}
			)}
		>
			{checked && (
				<svg
					width="13"
					height="11"
					viewBox="0 0 13 11"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M1.64258 4.55583L5.80584 9.14314L11.3569 1.85742"
						stroke="white"
						stroke-width="2"
						stroke-linecap="round"
					/>
				</svg>
			)}
		</div>
	);
};
