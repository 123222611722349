import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useActuatorValueStore from 'store/useActuatorValueStore';
import {
	getInitialsPosition,
	getInitialsSpeed,
} from 'utils/actuator/variableSpeed';
export default function StrokeIntervalForm() {
	const { t } = useTranslation();
	const formRef = useRef<HTMLFormElement>(null);
	const {
		strokeIntervalsCount,
		setStrokeIntervalsCount,
		setPositionArray,
		setSpeedArray,
		speedMode,
	} = useActuatorValueStore();
	const [strokeIntervalsCountState, setStrokeIntervalsCountState] =
		useState<string>(`${strokeIntervalsCount}`);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const value = Number(strokeIntervalsCountState);
		if (isNaN(value) || value <= 0) setStrokeIntervalsCount(2);
		setStrokeIntervalsCount(value);
		setSpeedArray(getInitialsSpeed(value));
		setPositionArray(getInitialsPosition(value));
	};

	return (
		<form
			className={clsx('flex items-center gap-x-[15px]', {
				'opacity-50 pointer-events-none': speedMode === 'single',
			})}
			onSubmit={onSubmit}
			ref={formRef}
		>
			<div className="flex items-center gap-x-[30px]">
				<label className="text-sm font-medium">
					{t('actuator.speed_control.stroke_range')}
				</label>
				<div className="flex items-center gap-x-2">
					<input
						type="number"
						min={2}
						max={6}
						className={inputStyle}
						value={strokeIntervalsCountState}
						onChange={(e) => {
							const value = e.target.value;
							if (value === '') return setStrokeIntervalsCountState(value);
							const newValue = Number(value);
							if (isNaN(newValue)) return;
							setStrokeIntervalsCountState(value);
						}}
						onBlur={() => {
							//form의 submit 이벤트를 발생시킴
							const submitEvent = new Event('submit', {
								bubbles: true,
								cancelable: true,
							});
							formRef.current?.dispatchEvent(submitEvent);
						}}
					/>
				</div>
			</div>
		</form>
	);
}

// style
const inputStyle = clsx(
	'w-[70px] h-[35px] px-2.5 py-3 border-sub-color border',
	'items-center justify-center flex',
	'text-sm font-semibold '
);
