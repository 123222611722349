import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function CancelAccountSuccessPage() {
	const { t } = useTranslation();
	return (
		<main className="flex flex-col items-center justify-center w-screen h-screen py-6">
			<section className={boxStyle}>
				<h1 className="text-lg font-bold">
					{t('account_cancel.success.title')}
				</h1>
				<p>{t('account_cancel.success.description')}</p>
				<Link className={buttonStyle} to="/">
					{t('account_cancel.success.button')}
				</Link>
			</section>
		</main>
	);
}

// style
const boxStyle = clsx(
	'p-6 bg-white rounded-[10px] w-[460px] border border-[#EEEEEE]',
	'flex flex-col gap-y-[30px] items-center'
);
const buttonStyle = clsx(
	'px-2.5 py-[14px] font-bold text-white rounded bg-primary-blue w-[100%]',
	'flex items-center justify-center'
);
