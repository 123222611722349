import { contentsStyle, labelStyle } from './style';

export default function InputVoltageSpec() {
	const voltage = 0;

	return (
		<div className="flex flex-col flex-1 gap-y-2">
			<span className={labelStyle}>Input Voltage</span>
			<div className="flex justify-between w-full">
				<span className={contentsStyle}>Voltage:</span>
				<span className={contentsStyle}>{voltage}</span>
			</div>
		</div>
	);
}
