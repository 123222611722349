import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as FactCheck } from 'asset/icon/fact-check.svg';
import { primaryBlue } from 'utils/color';
export default function ResetResultPage() {
	const { t } = useTranslation();
	return (
		<main className="flex flex-col items-center justify-center h-screen">
			<div id="reset-result-box" className={boxStyle}>
				<div className="flex flex-col gap-y-[50px]">
					<div id="reset-result-empty" />
					<div className="flex flex-col items-center gap-y-5">
						<FactCheck fill={primaryBlue} />
						<p>{t('reset_result.description')}</p>
					</div>
					<Link to="/" className={buttonStyle}>
						{t('reset_result.button')}
					</Link>
				</div>
			</div>
		</main>
	);
}

// style
const boxStyle = clsx(
	'w-[460px] p-6 bg-white border border-[#EEEEEE] rounded-[10px]',
	'flex flex-col gap-y-[30px]'
);

const buttonStyle = clsx(
	'bg-primary-blue text-white rounded px-2.5 py-[14px]',
	'text-center font-bold'
);
