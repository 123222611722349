import DetectionContainer from './DetectionContainer';
import SpeedContainer from './SpeedContainer';
import StrokeContainer from './StrokeContainer';

export default function ValueControlArea() {
	return (
		<div className="flex flex-col gap-y-[5px] py-2.5">
			<StrokeContainer />
			<DetectionContainer />
			<SpeedContainer />
		</div>
	);
}
