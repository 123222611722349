import { useTranslation } from 'react-i18next';
import StrokeGraphRange from './StrokeGraphRange';
import SpeedGraphRange from './SpeedGraphRange';
import DetectionGraphRange from './DetectionGraphRange';
import AsyncBoundary from 'components/common/AsyncBoundary';

export default function GraphSettingArea() {
	const { t } = useTranslation();

	return (
		<div className="flex flex-col p-5 gap-y-[13px]">
			<h4 className="text-sm font-bold">
				{t('actuator.operation_graph.graph_setting')}
			</h4>
			<div className="flex flex-col gap-y-2.5">
				<AsyncBoundary SuspenseFallback={<div>Loading...</div>}>
					<StrokeGraphRange />
					<DetectionGraphRange />
					<SpeedGraphRange />
				</AsyncBoundary>
			</div>
		</div>
	);
}
