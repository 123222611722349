import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ActuatorPlayButton from './ActuatorPlayButton';
import ActuatorStopButton from './ActuatorStopButton';
export default function ActuatorControlButtons() {
	const { t } = useTranslation();
	return (
		<div className="flex items-center gap-x-[14px]">
			<div className={getStatusStyle('stop')}>{t('actuator.stop')}</div>
			<ActuatorPlayButton action="playing" />
			<ActuatorStopButton />
		</div>
	);
}

// style
const getStatusStyle = (status: 'pause' | 'stop' | 'start') =>
	clsx(
		'text-white py-1.5 px-2 rounded text-xs font-bold',
		'w-[50px]',
		'text-center',
		{
			'bg-warn-red': status === 'stop',
			'bg-[#66D703]': status === 'start',
			'bg-[#FFAE00]': status === 'pause',
		}
	);
