import { userResetPassword } from 'api/reset/userResetPassword';
import { useState } from 'react';
import useForgetStore from 'store/useForgetStore';

export function useForget() {
	const [submitStatus, setSubmitStatus] = useState<
		'success' | 'fail' | 'unexist' | null
	>(null);
	const { email, name } = useForgetStore();
	const isValidateEmail = (email: string) => {
		const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegexp.test(email);
	};
	const isValidateName = (name: string) => {
		return name.length > 0;
	};

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		// 서버에 요청 보내기
		try {
			const response = await userResetPassword({ email, name });
			if (response === 'unexist') {
				setSubmitStatus('unexist');
				return;
			}
			setSubmitStatus('success');
		} catch (error) {
			setSubmitStatus('fail');
			return;
		}
	};

	const disable = !isValidateEmail(email) || !isValidateName(name);
	return {
		email,
		name,
		isValidateName,
		isValidateEmail,
		disable,
		submitStatus,
		onSubmit,
	};
}
