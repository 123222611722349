import customAxios from 'api';
import axios from 'axios';
import useAuthStore from 'store/useAuthStore';

type Response = {
	message: string;
};
// 로그아웃
export const userLogout = async () => {
	try {
		const response = await customAxios.post<Response>('/user/userLogout.php');
		//accessToken 삭제
		useAuthStore.getState().setAccessToken(null);
		return response.data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error('Axios error:', error.response?.data || error.message);
		} else {
			console.error('Unexpected error:', error);
		}
		throw error; // 오류를 호출한 함수로 전달
	}
};
