import ActuatorControlButtons from './ActuatorControlButtons';
import PresetSaveButton from './PresetSaveButton';
import PresetImportButton from './PresetImportButton';
import CalibrationButton from './CalibrationButton';
import useSelectedActuatorStore from 'store/useSelectedActuatorStore';
import { useMotorSettingsQuery } from 'query/useMotorSettingsQuery';
import { useMotorInfoQuery } from 'query/useMotorInfoQuery';
import AsyncBoundary from 'components/common/AsyncBoundary';

export default function ActuatorHead() {
	return (
		<div className="flex justify-between p-5">
			<div id="actuator-head-left" className="flex items-center gap-x-5">
				<AsyncBoundary>
					<ActuatorName />
				</AsyncBoundary>
				{/* <div id="actuator-head-spec">
					<span className="text-sm">{t('actuator.spec')}:</span>
				</div> */}
			</div>
			<div id="actuator-head-right" className="flex items-center gap-x-6">
				<div className="border-sub-color border w-0 h-[25px]" />
				<ActuatorControlButtons />
				<div className="border-sub-color border w-0 h-[25px]" />
				<div className="flex items-center gap-x-2">
					<PresetSaveButton />
					<PresetImportButton />
					<CalibrationButton />
				</div>
			</div>
		</div>
	);
}

const ActuatorName = () => {
	const { selectedActuatorNumber } = useSelectedActuatorStore();
	const { data: motorInfoData } = useMotorInfoQuery();
	const { data: motorSettings } = useMotorSettingsQuery();

	const motorId = motorSettings?.find(
		(setting) => setting.actuator_number === selectedActuatorNumber
	)?.motor_info_id;
	if (!motorId) return null;
	const name = motorInfoData?.motorInfos?.find(
		(info) => info.id === motorId
	)?.model_name;
	return <span className="text-lg font-bold">{name}</span>;
};
